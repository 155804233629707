import {
  IconButton,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Tooltip,
} from "@chakra-ui/react";
import { RiArrowRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";

type ServiceUserDataProps = {
  label: string;
  value: string;
  extra?: string;
  capitalise?: boolean;
  link?: {
    to: string;
    ariaLabel: string;
  };
};

export function ServiceUserData({
  label,
  value,
  extra,
  capitalise,
  link,
}: ServiceUserDataProps) {
  return (
    <Stat>
      <StatLabel fontWeight="600" fontSize="sm" color="gray.700">
        {label}
      </StatLabel>
      <StatNumber
        _firstLetter={{ textTransform: capitalise ? "capitalize" : undefined }}
        fontWeight="400"
        fontSize="md">
        {value}
        {link && (
          <Tooltip label={link.ariaLabel}>
            <IconButton
              ml="2"
              size="sm"
              variant="ghost"
              aria-label={link.ariaLabel}
              icon={<RiArrowRightLine />}
              as={Link}
              to={link.to}
            />
          </Tooltip>
        )}
      </StatNumber>
      {extra && (
        <StatHelpText fontWeight="400" fontSize="sm" fontStyle="italic">
          {extra}
        </StatHelpText>
      )}
    </Stat>
  );
}
