/** Base extensions */
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
/** Nodes */
import BulletList from "@tiptap/extension-bullet-list";
import OrderedList from "@tiptap/extension-ordered-list";
import ListItem from "@tiptap/extension-list-item";
import Heading from "@tiptap/extension-heading";
import HorizontalRule from "@tiptap/extension-horizontal-rule";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TableHeader from "@tiptap/extension-table-header";
import TableCell from "@tiptap/extension-table-cell";
/** Styling marks */
import Bold from "@tiptap/extension-bold";
import Italic from "@tiptap/extension-italic";
import Highlight from "@tiptap/extension-highlight";
import Link from "@tiptap/extension-link";
import Strike from "@tiptap/extension-strike";
import Underline from "@tiptap/extension-underline";
import Subscript from "@tiptap/extension-subscript";
import Superscript from "@tiptap/extension-superscript";
/** Text style mark */
import TextStyle from "@tiptap/extension-text-style";
import Color from "@tiptap/extension-color";
/** Utilities */
import CharacterCount from "@tiptap/extension-character-count";
import Dropcurser from "@tiptap/extension-dropcursor";
import Gapcurser from "@tiptap/extension-gapcursor";
import Placeholder from "@tiptap/extension-placeholder";
import TextAlign from "@tiptap/extension-text-align";
import History from "@tiptap/extension-history";
import Focus from "@tiptap/extension-focus";
import BubbleMenu from "@tiptap/extension-bubble-menu";

import { Extensions, useEditor, UseEditorOptions } from "@tiptap/react";
import { useMemo } from "react";


type Params = { limit: number; placeholder: string } & Omit<
  UseEditorOptions,
  "extensions"
>;

export const getEditorExtensions = (
  limit = 15000,
  placeholder = "Start typing here",
) => [
  // Base, required extensions
  Document,
  Paragraph,
  Text,

  // Nodes
  BulletList,
  OrderedList,
  ListItem,
  Heading.configure({
    levels: [1, 2, 3, 4],
  }),
  HorizontalRule,
  Table,
  TableRow,
  TableHeader,
  TableCell,

  // Styling marks
  Bold,
  Italic,
  Highlight,
  Link,
  Strike,
  Underline,
  Subscript,
  Superscript,

  // Text style mark (Special extensions that specifically use TextStyle to apply)
  TextStyle,
  Color,

  // Utilities
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  CharacterCount.configure({
    limit,
  }),
  Dropcurser,
  Gapcurser,
  Placeholder.configure({
    placeholder,
    showOnlyWhenEditable: false,
  }),
  History.configure({
    depth: 50,
  }),
  Focus.configure({
    className: "has-focus",
    mode: "all",
  }),
  BubbleMenu,
];

export function useEditorWithExtensions({
  limit = 15000,
  placeholder = "Start typing here",
  ...otherParams
}: Params) {
  const extensions = useMemo(
    () => getEditorExtensions(limit, placeholder),
    [limit, placeholder],
  );

  return useEditor({ extensions, ...otherParams });
}
