import {
  Alert,
  AlertIcon,
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Skeleton,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  chakra,
  useDisclosure,
} from "@chakra-ui/react";
import { skipToken } from "@tanstack/react-query";
import { useState } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import UserAccountStatusBadge from "../../components/UI/UserAccountStatusBadge";
import { RefreshIcon } from "../../constants/commonIcons";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import { api } from "../../utilities/apiRequest";
import {
  formatDateString,
  formatUtcDateReadableLong,
} from "../../utilities/dateUtils";
import useToast from "../../utilities/useToast";
import NotFoundScreen from "../NotFound";
import { ConfirmDeleteAccountModal } from "./components/ConfirmDeleteAccountModal";
import { EditAccountNameModal } from "./components/EditAccountNameModal";
import { ServiceAccountDetailsCard } from "./components/serviceAccountCards/ServiceAccountDetailsCard";
import { ServiceAccountLicenseCard } from "./components/serviceAccountCards/ServiceAccountLicenseCard";
import { ServiceAccountTagsCard } from "./components/serviceAccountCards/ServiceAccountTagsCard";
import { ServiceDataSectionCard } from "./components/ServiceDataSectionCard";
import { ServiceHistoryTimelineItem } from "./components/ServiceHistoryTimelineItem";
import { ServiceUserData } from "./components/ServiceUserData";
import ServiceScreenContainer from "./ServiceScreenContainer";
import { ServiceAccountImportExportCard } from "./components/serviceAccountCards/ServiceAccountImportExportCard";

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceAccountScreen({ servicePermissions }: Props) {
  const { accountID } = useParams();

  const { displayToast } = useToast();

  const apiUtils = api.useUtils();
  const accountQuery = api.admin.account.getByID.useQuery(
    accountID
      ? {
          accountID,
        }
      : skipToken,
  );
  const [usersPage, setUsersPage] = useState(0);
  const usersQuery = api.admin.user.list.useQuery(
    accountID
      ? {
          filter: { inAccountID: accountID },
          pagination: {
            page: usersPage,
          },
        }
      : skipToken,
  );
  const historyQuery = api.admin.history.list.useQuery(
    accountID
      ? {
          targetAccountID: accountID,
        }
      : skipToken,
  );
  const activateAccountMutation = api.admin.account.activate.useMutation();
  const deactivateAccountMutation = api.admin.account.deactivate.useMutation();
  const restoreAccountMutation = api.admin.account.restoreByID.useMutation();
  // const markAsInternalMutation = api.admin.user.markInternal.useMutation();
  // const clearMfaMutation = api.admin.user.clearMfa.useMutation();

  const anyIsFetching =
    accountQuery.isFetching ||
    usersQuery.isFetching ||
    activateAccountMutation.isPending ||
    deactivateAccountMutation.isPending;

  const updateAccountNameModalState = useDisclosure();
  const confirmDeleteAccountModalState = useDisclosure();

  const handleRefresh = () => {
    apiUtils.admin.account.getByID.invalidate();
    apiUtils.admin.history.list.invalidate();
    apiUtils.admin.user.list.invalidate();
    setUsersPage(0);
  };

  const handleClickEditAccountName = async () => {
    updateAccountNameModalState.onOpen();
  };

  const handleClickDeactivateAccount = async () => {
    if (!accountQuery.isSuccess) {
      return;
    }
    await deactivateAccountMutation.mutateAsync({
      accountID: accountQuery.data.account.accountID,
    });
    handleRefresh();
  };

  const handleClickActivateAccount = async () => {
    if (!accountQuery.isSuccess) {
      return;
    }
    await activateAccountMutation.mutateAsync({
      accountID: accountQuery.data.account.accountID,
    });
    handleRefresh();
  };

  const handleClickDeleteAccount = async () => {
    // Only allow deletion of inactive accounts
    if (
      !accountQuery.isSuccess ||
      accountQuery.data.account.accountStatus === "active"
    ) {
      return;
    }
    confirmDeleteAccountModalState.onOpen();
  };

  const handleClickRestoreAccount = async () => {
    if (
      !accountQuery.isSuccess ||
      accountQuery.data.account.accountStatus === "active"
    ) {
      return;
    }
    await restoreAccountMutation.mutateAsync({
      accountID: accountQuery.data.account.accountID,
    });
    handleRefresh();
  };

  // const handleClickResetPassword = async () => {
  //   passwordResetModalState.onOpen();
  // };
  // const handleClickClearMFA = async () => {
  //   try {
  //     if (!userQuery.isSuccess) {
  //       return;
  //     }

  //     await clearMfaMutation.mutateAsync({
  //       userID: userQuery.data.user.userID,
  //     });

  //     displayToast({
  //       title: "Success",
  //       description: "MFA Cleared",
  //       status: "success",
  //     });

  //     apiUtils.admin.user.getByID.invalidate();
  //     apiUtils.admin.history.list.invalidate();
  //   } catch (err) {
  //     displayToast({
  //       title: "Error",
  //       description: `Error clearing MFA: ${err.m}`,
  //       status: "error",
  //     });
  //     userModalState.onClose();
  //   }
  // };
  // const handleClickMarkInternal = async () => {
  //   if (!userQuery.isSuccess) {
  //     return;
  //   }
  //   const { user } = userQuery.data;
  //   try {
  //     apiUtils.admin.user.getByID.setData(
  //       {
  //         userID: user.userID || "",
  //       },
  //       {
  //         ...userQuery.data,
  //         user: {
  //           ...user,
  //           isInternal: !user.isInternal,
  //         },
  //       },
  //     );
  //     const result = await markAsInternalMutation.mutateAsync({
  //       userID: user.userID,
  //       isInternal: !user.isInternal,
  //     });
  //     displayToast({
  //       title: "Success",
  //       description: "User has been updated",
  //       status: "success",
  //     });
  //     if (result.user) {
  //       apiUtils.admin.user.getByID.setData(
  //         {
  //           userID: user.userID || "",
  //         },
  //         {
  //           ...userQuery.data,
  //           user: result.user,
  //         },
  //       );
  //     } else {
  //       apiUtils.admin.user.getByID.invalidate();
  //     }
  //     apiUtils.admin.history.list.invalidate();
  //   } catch (err) {
  //     displayToast({
  //       title: "Error",
  //       description: `Error updating user`,
  //       status: "error",
  //     });
  //     apiUtils.admin.user.getByID.invalidate();
  //     apiUtils.admin.history.list.invalidate();
  //   }
  // };

  if (!servicePermissions["accounts:manage"] || !servicePermissions["access"]) {
    return <NotFoundScreen />;
  }

  const pageTitle = accountQuery.isSuccess
    ? accountQuery.data.account.accountName
    : "Manage Account";

  return (
    <chakra-scope>
      <ServiceScreenContainer
        size="md"
        pageTitle={pageTitle}
        breadcrumbs={[
          { label: "Service Portal", link: "/_ccserviceportal" },
          { label: "Manage Accounts", link: "/_ccserviceportal/accounts" },
        ]}>
        {accountQuery.isLoading && (
          <SimpleGrid columns={2} spacing={12} mt="4">
            <VStack w="full" spacing={4}>
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
            </VStack>
            <VStack w="full" spacing={4}>
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
            </VStack>
          </SimpleGrid>
        )}
        {accountQuery.isSuccess && (
          <VStack pt="4" pb="4" w="100%">
            {accountQuery.data.account.accountStatus === "deleted" && (
              <Alert status="warning">
                <AlertIcon />
                This account has been scheduled for deletion.
              </Alert>
            )}
            <Grid w="100%" templateColumns="minmax(65%, 1fr) 1fr;" gap="10">
              <GridItem>
                <VStack flexGrow="1" spacing="4">
                  <ServiceAccountDetailsCard
                    isFetching={anyIsFetching}
                    account={accountQuery.data.account}
                    onClickEditAccountName={handleClickEditAccountName}
                    onClickDeactivateAccount={handleClickDeactivateAccount}
                    onClickReactivateAccount={handleClickActivateAccount}
                    onClickDeleteAccount={handleClickDeleteAccount}
                    onClickRestoreAccount={handleClickRestoreAccount}
                  />
                  <ServiceAccountTagsCard
                    isFetching={anyIsFetching}
                    account={accountQuery.data.account}
                    onClickSetTag={(tag) => {}}
                  />
                  <ServiceAccountLicenseCard
                    isFetching={anyIsFetching}
                    account={accountQuery.data.account}
                    onClickSetLicenseAmount={() => {}}
                  />
                  <ServiceAccountImportExportCard
                    isFetching={anyIsFetching}
                    account={accountQuery.data.account}
                    users={usersQuery.data?.users || []}
                  />
                  <EditAccountNameModal
                    account={accountQuery.data.account}
                    onClose={updateAccountNameModalState.onClose}
                    isOpen={updateAccountNameModalState.isOpen}
                    onRefresh={handleRefresh}
                  />
                  <ConfirmDeleteAccountModal
                    account={accountQuery.data.account}
                    isOpen={confirmDeleteAccountModalState.isOpen}
                    onClose={confirmDeleteAccountModalState.onClose}
                    onRefresh={handleRefresh}
                  />
                  <ServiceDataSectionCard
                    title="Users"
                    actions={
                      <>
                        <Text>
                          <strong>
                            {accountQuery.data.account.userCounts.activeUsers}
                          </strong>{" "}
                          active
                        </Text>
                        <Text>⁃</Text>
                        <Text>
                          <strong>
                            {accountQuery.data.account.userCounts.pendingUsers}
                          </strong>{" "}
                          pending
                        </Text>
                        <Text>⁃</Text>
                        <Text>
                          <strong>
                            {accountQuery.data.account.userCounts.internalUsers}
                          </strong>{" "}
                          internal
                        </Text>
                      </>
                    }>
                    <TableContainer>
                      <Table size="sm" colorScheme="gray" variant="striped">
                        <Thead position="relative">
                          <Tr>
                            <Th>User</Th>
                            <Th>Email</Th>
                            <Th>Is internal</Th>
                            <Th>Status</Th>
                            <Th>Invited on</Th>
                          </Tr>
                        </Thead>
                        <Tbody
                          opacity={
                            usersQuery.isLoading || usersQuery.isFetching
                              ? 0.4
                              : 1
                          }>
                          {usersQuery.isSuccess &&
                            usersQuery.data.users.map((u) => (
                              <LinkBox as={Tr} key={u.userID}>
                                <Td display="flex">
                                  <LinkOverlay
                                    ps="2"
                                    as={RouterLink}
                                    flexDir="column"
                                    alignItems="start"
                                    to={`/_ccserviceportal/users/${u.userID}`}>
                                    <Flex
                                      flexDir="column"
                                      align="start"
                                      as="span">
                                      <chakra.span>
                                        {" "}
                                        <Badge colorScheme="gray">
                                          {" "}
                                          {u.userID}{" "}
                                        </Badge>
                                      </chakra.span>
                                      <Text>
                                        {u.firstName} {u.lastName}
                                      </Text>
                                    </Flex>
                                  </LinkOverlay>
                                </Td>
                                <Td>{u.primaryEmail}</Td>
                                <Td>{u.isInternal ? "Internal" : "Normal"}</Td>
                                {(() => {
                                  const userAccount = u.userAccounts.find(
                                    (ua) => ua.account.accountID === accountID,
                                  );
                                  if (!userAccount)
                                    return (
                                      <>
                                        <Td /> <Td />
                                      </>
                                    );
                                  return (
                                    <>
                                      <Td>
                                        <UserAccountStatusBadge
                                          statusID={
                                            userAccount.userAccountStatusID
                                          }
                                        />
                                      </Td>
                                      <Td>
                                        {formatUtcDateReadableLong(
                                          new Date(userAccount.createTs),
                                        )}
                                      </Td>
                                    </>
                                  );
                                })()}
                              </LinkBox>
                            ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </ServiceDataSectionCard>
                </VStack>
              </GridItem>
              <GridItem>
                <VStack
                  position="sticky"
                  top="calc(var(--chakra-space-top-bar) + 2.5rem)"
                  flexGrow="1"
                  align="start"
                  justify="start">
                  <Button
                    alignSelf="end"
                    isLoading={accountQuery.isFetching}
                    onClick={handleRefresh}
                    leftIcon={<Icon as={RefreshIcon} />}
                    variant="ghost"
                    loadingText="Refreshing"
                    size="sm">
                    Refresh
                  </Button>
                  <ServiceUserData
                    label="DB ID"
                    value={accountQuery.data.account.accountID}
                  />
                  <Divider />
                  <ServiceUserData
                    label="Created"
                    value={
                      accountQuery.data.account.createTs
                        ? formatDateString(
                            new Date(accountQuery.data.account.createTs),
                          )
                        : "Never"
                    }
                  />
                  <Divider />
                  <ServiceUserData
                    label="Last god tool run"
                    value={
                      accountQuery.data.account.lastGodToolRunTs
                        ? formatDateString(
                            new Date(
                              accountQuery.data.account.lastGodToolRunTs,
                            ),
                          )
                        : "Never"
                    }
                  />
                  <Divider />
                  <Heading size="sm">Service history</Heading>
                  <VStack spacing="0" align="start">
                    {historyQuery.isSuccess &&
                      historyQuery.data.history.map((h) => (
                        <ServiceHistoryTimelineItem
                          key={h.serviceHistoryID}
                          history={h}
                        />
                      ))}
                    {historyQuery.isLoading && (
                      <>
                        <Skeleton h={6} w="90%" />
                        <Skeleton h={6} w="90%" />
                        <Skeleton h={6} w="90%" />
                        <Skeleton h={6} w="70%" />
                        <Skeleton h={6} w="80%" />
                      </>
                    )}
                  </VStack>
                </VStack>
              </GridItem>
            </Grid>
          </VStack>
        )}
      </ServiceScreenContainer>
    </chakra-scope>
  );
}
