import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  chakra,
  Flex,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack
} from "@chakra-ui/react";
import { parseAsInteger, useQueryState } from "nuqs";
import { useCallback, useState } from "react";
import { RiExpandLeftRightLine, RiInformationLine } from "react-icons/ri";
import { Link as RouterLink } from "react-router-dom";
import Spinner from "../../components/chakra/common/Spinner";
import DebouncedInput from "../../components/DebouncedInput";
import { RefreshIcon } from "../../constants/commonIcons";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import { api } from "../../utilities/apiRequest";
import { formatDateSinceNow } from "../../utilities/dateUtils";
import NotFoundScreen from "../NotFound";
import ServiceScreenContainer from "./ServiceScreenContainer";
import { useScreenExpansion } from "./useScreenExpansion";

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceManageUsersScreen({
  servicePermissions,
}: Props) {
  // Query params
  const apiUtils = api.useUtils();
  const [page, setPage] = useQueryState("page", parseAsInteger.withDefault(0));
  const [email, setEmail] = useQueryState("email");
  const [name, setName] = useQueryState("name");

  const [isScreenExpanded, setIsScreenExpanded] = useScreenExpansion();

  const onClickExpandTable = () => {
    setIsScreenExpanded(!isScreenExpanded);
  };

  const handleClickRefresh = () => {
    apiUtils.admin.user.list.invalidate();
  };

  const usersQuery = api.admin.user.list.useQuery({
    pagination: {
      page,
    },
    filter: {
      name: name || undefined,
      email: email || undefined,
    },
  });

  const emailSearchHandler = useCallback(
    (value?: string) => {
      setPage(0);
      setEmail(value || null);
    },
    [setEmail, setPage],
  );

  const nameSearchHandler = useCallback(
    (value?: string) => {
      setPage(0);
      setName(value || null);
    },
    [setName, setPage],
  );

  if (!servicePermissions["users:read"]) {
    return <NotFoundScreen />;
  }

  const { isFetching, isLoading, isSuccess } = usersQuery;

  const renderPaginator = () => {
    const hasNext =
      !usersQuery.isFetching && usersQuery.data?.pagination.hasNext;
    const hasPrev = !usersQuery.isFetching && page > 0;
    const pageSize = usersQuery.data?.pagination.size || 0;
    const numResults = usersQuery.data?.pagination.count || 0;
    const resultFrom = page * pageSize + 1;
    const resultTo = resultFrom + numResults - 1;
    const resultText = usersQuery.isSuccess ? `${resultFrom} - ${resultTo}` : " - ";
    return (
      <HStack align="center">
        <Text>Results {resultText}</Text>
        <ButtonGroup size="sm" isAttached variant="outline">
          <Button
            onClick={() => {
              if (!usersQuery.data) return;
              const toPage = usersQuery.data.pagination.page - 1;
              setPage(toPage);
            }}
            isDisabled={!hasPrev}>
            Prev
          </Button>
          <Button
            onClick={() => {
              if (!usersQuery.data) return;
              const toPage = usersQuery.data.pagination.page + 1;
              setPage(toPage);
            }}
            isDisabled={!hasNext}>
            Next
          </Button>
        </ButtonGroup>
      </HStack>
    );
  };

  const renderSearch = () => {
    return (
      <HStack w="100%" justify="space-between" align="end">
        <Flex gap={4}>
          <div>
            <FormLabel htmlFor="name">Name</FormLabel>
            <DebouncedInput
              onChange={nameSearchHandler}
              value={name || undefined}
              placeholder="Search by users name"
            />
          </div>
          <div>
            <FormLabel htmlFor="email">Email</FormLabel>
            <DebouncedInput
              onChange={emailSearchHandler}
              value={email || undefined}
              placeholder="Search by email"
            />
          </div>
        </Flex>
        <Box>
          <Button
            isLoading={usersQuery.isRefetching}
            disabled={usersQuery.isFetching}
            onClick={handleClickRefresh}
            leftIcon={<Icon as={RefreshIcon} />}
            variant="ghost"
            loadingText="Refreshing"
            size="sm">
            Refresh
          </Button>
          <IconButton
            onClick={onClickExpandTable}
            size="md"
            variant="ghost"
            aria-label="Expand/Collapse table"
            icon={<RiExpandLeftRightLine />}
          />
        </Box>
      </HStack>
    );
  };

  return (
    <ServiceScreenContainer
      isStatic
      pageTitle="Manage Users"
      size={isScreenExpanded ? "full" : "lg"}
      breadcrumbs={[{ label: "Service Portal", link: "/_ccserviceportal" }]}>
      <VStack align="start" w="100%" h="100%" py="2">
        {renderSearch()}
        <TableContainer
          flexGrow="1"
          h="100%"
          w="full"
          overflowY="auto"
          borderRadius="lg"
          borderStyle="solid"
          borderWidth="1px">
          <Table
            isFetching={isFetching}
            variant="data-table"
            size="sm"
            position="relative"
            sx={{
              "& thead tr th": {
                paddingTop: 2,
                paddingBottom: 2,
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              },
            }}>
            <Thead zIndex="4" position="relative" maxH="fit-content">
              <Tr>
                <Th>User</Th>
                <Th>Primary Email</Th>
                <Th>Tags</Th>
                <Th>Last Active</Th>
                <Th>Accounts</Th>
              </Tr>
            </Thead>
            {isLoading && (
              <Tbody h="100%">
                <Tr h="100%">
                  <Td colSpan={5}>
                    <Flex
                      justifyContent="center"
                      alignItems="start"
                      justify="center"
                      h="100%">
                      <Flex h="70%" alignItems="center" justify="center">
                        <Spinner size="xl" />
                      </Flex>
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            )}

            {isSuccess && (
              <>
                <Tbody>
                  {usersQuery.data?.users.map((user) => (
                    <Tr key={user.userID} maxH="fit-content">
                      <LinkBox
                        _hover={{ textDecor: "underline" }}
                        as={Td}
                        maxW={"20rem"}
                        overflowX="hidden">
                        <LinkOverlay
                          ps="2"
                          as={RouterLink}
                          display="flex"
                          flexDir="column"
                          alignItems="start"
                          to={`/_ccserviceportal/users/${user.userID}`}>
                          <chakra.span fontWeight="bold">
                            {user.firstName} {user.lastName}
                          </chakra.span>
                          <Text
                            color="gray.600"
                            fontWeight="300"
                            fontStyle="italic">
                            [{user.userID}]
                          </Text>
                        </LinkOverlay>
                      </LinkBox>
                      <Td> {user.primaryEmail} </Td>
                      <Td>
                        {" "}
                        {user.isInternal ? (
                          <Badge>Internal</Badge>
                        ) : (
                          <Box
                            as="span"
                            color="gray.600"
                            fontWeight="400"
                            fontStyle="italic">
                            None...
                          </Box>
                        )}{" "}
                      </Td>
                      <Td>
                        {user.auth.lastActiveTs ? (
                          <Tooltip
                            label={new Date(
                              user.auth.lastActiveTs,
                            ).toLocaleString()}>
                            <HStack as="span" align="center">
                              <Box as="span" textTransform="capitalize">
                                {formatDateSinceNow(
                                  new Date(user.auth.lastActiveTs),
                                )}
                              </Box>
                              <Icon
                                boxSize={4}
                                color="blue.300"
                                as={RiInformationLine}
                              />
                            </HStack>
                          </Tooltip>
                        ) : (
                          <Box
                            as="span"
                            color="gray.600"
                            fontWeight="400"
                            fontStyle="italic">
                            Never...
                          </Box>
                        )}
                      </Td>
                      <Td>
                        <Tooltip
                          label={user.userAccounts
                            .map((ua) => ua.account.name)
                            .join(", ")}>
                          <HStack as="span" align="center">
                            <span>{user.userAccounts.length} Accounts</span>
                            <Icon
                              boxSize={4}
                              color="blue.300"
                              as={RiInformationLine}
                            />
                          </HStack>
                        </Tooltip>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>

                {/* <Tfoot>
                  <Tr>
                    <Th>User</Th>
                    <Th>Primary Email</Th>
                    <Th>Tags</Th>
                    <Th>Last Active</Th>
                    <Th>Last Sign-in</Th>
                    <Th>Accounts</Th>
                  </Tr>
                </Tfoot> */}
              </>
            )}
          </Table>
        </TableContainer>
        <HStack justify="end" w="100%">
          <ButtonGroup size="sm" variant="outline" isAttached>
            {renderPaginator()}
          </ButtonGroup>
        </HStack>
      </VStack>
    </ServiceScreenContainer>
  );
}
