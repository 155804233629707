import { httpBatchLink } from "@trpc/client";
import { createTRPCReact } from "@trpc/react-query";
import { useEffect, useRef, useState } from "react";
import type { AppRouter } from "../../../api/application/apibel/funcs/mainv3";
import { apiConfig } from "../constants/config";

export const api = createTRPCReact<AppRouter>();

const createClient = (getToken: () => Promise<string | null>) => {
  return api.createClient({
    links: [
      httpBatchLink({
        url: apiConfig.apibelBaseUrlV3,
        async headers() {
          console.log("Getting headers");
          const token = await getToken();
          if (token) {
          } else {
            console.log("No token");
          }
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      }),
    ],
  });
};

export const useTrpcClient = (getToken: () => Promise<string | null>) => {
  const clientRef = useRef<ReturnType<typeof createClient>>();

  useEffect(() => {
    console.log("Building RPC client");
    const newClient = createClient(getToken);
    clientRef.current = newClient;
  }, [getToken]);

  return clientRef.current;
};
