import {
  Badge,
  Box,
  Button,
  chakra,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { RiLock2Fill, RiMacbookLine } from "react-icons/ri";
import CenteredAlert from "../../../../components/chakra/common/CenteredAlert";
import Editable from "../../../../components/chakra/common/Editable";
import { MoreMenuIcon } from "../../../../constants/commonIcons";
import {
  useApiMutation,
  useApiQuery,
} from "../../../../utilities/apibelRequest";
import { formatDateSinceNow } from "../../../../utilities/dateUtils";
import {
  AlternateAuthEmail,
  useAuthEmails,
  useAuthPasswords,
  useAuthSessionsQuery,
  useAuthTOTP,
} from "../../../../utilities/useAuth";
import useEditableState from "../../../../utilities/useEditableState";
import useToast from "../../../../utilities/useToast";
import ChangePasswordModal from "./ChangePasswordModal";
import { ConfirmDeleteEmailModal } from "./ConfirmDeleteEmailModal";
import { ConfirmDeletePasswordModal } from "./ConfirmDeletePasswordModal";
import CreateEmailModal, { CreateEmailModalMode } from "./CreateEmailModal";
import SetupMFAModal from "./SetupMFAModal";

export default function UserDetailsCard() {
  const changePasswordModalState = useDisclosure({ defaultIsOpen: false });
  const [changePasswordType, setChangePasswordType] = useState<
    "initial" | "update"
  >("initial");
  const setupMFAModalState = useDisclosure({ defaultIsOpen: false });

  const createEmailModal = useDisclosure({ defaultIsOpen: false });
  const [createEmailModalMode, setCreateEmailModalMode] =
    useState<CreateEmailModalMode>({
      type: "create",
    });
  const queryClient = useQueryClient();

  const confirmDeleteEmailModal = useDisclosure({ defaultIsOpen: false });
  const [confirmDeleteEmailState, setConfirmDeleteEmailState] =
    useState<null | AlternateAuthEmail>(null);

  const confirmDeletePasswordModal = useDisclosure({ defaultIsOpen: false });

  const totpUser = useAuthTOTP();
  const authEmails = useAuthEmails();
  const sessionsQuery = useAuthSessionsQuery();
  const authPasswords = useAuthPasswords();

  const { displayToast } = useToast();

  const userDetails = useApiQuery("user/profile", null);

  const updateUserDetailsMutation = useApiMutation(
    "userSettings/updateDetails",
  );

  const handleClickChangePassword = () => {
    setChangePasswordType(
      authPasswords?.passwordEnabled ? "update" : "initial",
    );
    changePasswordModalState.onOpen();
  };

  const firstNameState = useEditableState(userDetails.data?.firstName || "");
  const lastNameState = useEditableState(userDetails.data?.lastName || "");

  const handleClickDeleteEmail = (email: AlternateAuthEmail) => {
    setConfirmDeleteEmailState(email);
    confirmDeleteEmailModal.onOpen();
  };

  const handleCancelDeleteEmail = () => {
    confirmDeleteEmailModal.onClose();
    setConfirmDeleteEmailState(null);
  };

  const handleConfirmDeleteEmail = async () => {
    await confirmDeleteEmailState?.delete();
    setConfirmDeleteEmailState(null);
    confirmDeleteEmailModal.onClose();
    displayToast({
      status: "success",
      title: "Removing email",
    });
  };

  const handleClickAddAlternateEmail = () => {
    setCreateEmailModalMode({ type: "create" });
    createEmailModal.onOpen();
  };

  const handleClickVerifyEmail = async (email: AlternateAuthEmail) => {
    if (email.isVerified) return;
    await email.sendVerificationLink();
    setCreateEmailModalMode({ type: "verify", email: email.emailAddress });
    createEmailModal.onOpen();
  };

  const handleCreateEmail = async (emailAddress: string) => {
    if (authEmails) {
      const res = await authEmails.createEmail(emailAddress);
      return res;
    }
    return undefined;
  };

  const invalidateQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ["user", "profile"],
    });
  };

  const handleUpdateFirstName = async () => {
    const firstName = firstNameState.currentValue;
    firstNameState.setIsLoading(true);
    try {
      const res = await updateUserDetailsMutation.mutateAsync({
        firstName,
      });
      if (res) {
        displayToast({
          status: "success",
          title: "First Name successfully updated",
        });
      }
      queryClient.setQueriesData<typeof userDetails.data>(
        {
          queryKey: ["user", "profile"],
        },
        (prev) => (prev === undefined ? undefined : { ...prev, firstName }),
      );
      invalidateQuery();
    } catch (error) {
      console.warn(error);
      displayToast({
        status: "error",
        title: "Failed to update user details",
      });
      firstNameState.resetValue();
    }
    firstNameState.setIsLoading(false);
  };

  const handleUpdateLastName = async () => {
    const lastName = lastNameState.currentValue;
    lastNameState.setIsLoading(true);
    try {
      const res = await updateUserDetailsMutation.mutateAsync({
        lastName,
      });
      if (res) {
        displayToast({
          status: "success",
          title: "Last Name successfully updated",
        });
      }
      queryClient.setQueriesData<typeof userDetails.data>(
        { queryKey: ["user", "profile"] },
        (prev) => (prev === undefined ? undefined : { ...prev, lastName }),
      );
      invalidateQuery();
    } catch (error) {
      console.warn(error);
      displayToast({
        status: "error",
        title: "Failed to update user details",
      });
      lastNameState.resetValue();
    }
    lastNameState.setIsLoading(false);
  };

  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: [],
    },
  });
  const { handleSubmit } = form;

  return (
    <>
      <SetupMFAModal
        onRefresh={invalidateQuery}
        modalState={setupMFAModalState}
      />
      <ChangePasswordModal
        onRefresh={invalidateQuery}
        modalState={changePasswordModalState}
        type={changePasswordType}
      />
      <CreateEmailModal
        setModalMode={setCreateEmailModalMode}
        modalMode={createEmailModalMode}
        onRefresh={invalidateQuery}
        modalState={createEmailModal}
        onCreateEmail={handleCreateEmail}
      />
      <ConfirmDeleteEmailModal
        isOpen={confirmDeleteEmailModal.isOpen}
        onClose={handleCancelDeleteEmail}
        email={confirmDeleteEmailState?.emailAddress || ""}
        onDelete={handleConfirmDeleteEmail}
      />
      <ConfirmDeletePasswordModal
        isOpen={confirmDeletePasswordModal.isOpen}
        onClose={confirmDeletePasswordModal.onClose}
      />
      <VStack align="start" flex="1.25" minW="250px" spacing="6">
        <Heading size="md">Your Details</Heading>

        <VStack align="start" w="full" spacing={4}>
          {userDetails.isLoading ? (
            <>
              <Skeleton h="8" w="20%" />
              <Skeleton h="6" w="40%" />
              <Skeleton h="8" w="30%" />

              <Skeleton h="8" w="20%" />
              <Skeleton h="8" w="30%" />
              <Skeleton h="8" w="80%" />
            </>
          ) : userDetails.isSuccess ? (
            <>
              <VStack align="start" w="full">
                {authEmails ? (
                  <>
                    <Heading size="sm">Primary Email</Heading>
                    <Text>{authEmails?.primaryEmail?.emailAddress} </Text>

                    <Text color="gray.600" fontSize="sm">
                      To change your primary email, add an alternate email and
                      set it as primary.
                    </Text>
                    <Heading size="sm" pt="2">
                      Alternate Emails
                    </Heading>
                    {authEmails?.alternateEmails?.map((email) => (
                      <Box key={email.id} w="100%">
                        <HStack align="center" justify="space-between" w="100%">
                          <HStack align="center">
                            <Text>{email.emailAddress} </Text>
                            {!email.isVerified && (
                              <Badge
                                variant="subtle"
                                colorScheme="red"
                                fontSize="2xs">
                                Unverified
                              </Badge>
                            )}
                          </HStack>
                          <Menu size="sm">
                            <MenuButton
                              size="sm"
                              variant="ghost"
                              as={IconButton}
                              icon={<MoreMenuIcon />}
                            />
                            <MenuList>
                              {email.isVerified ? (
                                <MenuItem onClick={email.setPrimary}>
                                  Set as primary
                                </MenuItem>
                              ) : (
                                <MenuItem
                                  onClick={() => handleClickVerifyEmail(email)}>
                                  Verify
                                </MenuItem>
                              )}
                              <MenuItem
                                color="red.700"
                                onClick={() => handleClickDeleteEmail(email)}>
                                Remove email
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </HStack>
                      </Box>
                    ))}
                    {authEmails?.alternateEmails.length <= 2 && (
                      <HStack w="100%" justify="center" pt="2">
                        <Button
                          minW="50%"
                          size="sm"
                          variant="ghost"
                          onClick={handleClickAddAlternateEmail}>
                          Add alternate email
                        </Button>
                      </HStack>
                    )}
                    <Text color="gray.600" fontSize="sm">
                      An alternate email is optional, but it may be used by us
                      for verifying your identity if you lose access to your
                      account.
                    </Text>
                    <Divider />
                  </>
                ) : (
                  <>
                    <Skeleton h="8" w="20%" />
                    <Skeleton h="6" w="40%" />
                    <Skeleton h="8" w="30%" />
                  </>
                )}
              </VStack>
              <VStack align="start" w="full">
                <FormProvider {...form}>
                  <chakra.form w="full" onSubmit={handleSubmit(() => {})}>
                    <VStack w="full" align="start">
                      <FormControl>
                        <FormLabel>First Name</FormLabel>
                        <Editable
                          isLoading={firstNameState.isLoading}
                          isDisabled={userDetails.isFetching}
                          onSubmit={handleUpdateFirstName}
                          onCancel={firstNameState.resetValue}
                          value={firstNameState.currentValue}
                          onChange={(value) => firstNameState.setValue(value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Last Name</FormLabel>
                        <Editable
                          isLoading={lastNameState.isLoading}
                          isDisabled={userDetails.isFetching}
                          onSubmit={handleUpdateLastName}
                          onCancel={lastNameState.resetValue}
                          value={lastNameState.currentValue}
                          onChange={(value) => lastNameState.setValue(value)}
                        />
                      </FormControl>
                    </VStack>
                  </chakra.form>
                </FormProvider>
                <Divider />
              </VStack>
              <VStack align="start" w="full" spacing="4">
                <Heading size="sm">Security</Heading>
                <HStack w="100%" align="center">
                  <Box w="16rem">
                    <Text>Password</Text>
                  </Box>
                  <HStack
                    align="center"
                    minW="0px"
                    width="100%"
                    justifyContent="space-between">
                    {authPasswords?.passwordEnabled ? (
                      <Text>••••••••••</Text>
                    ) : (
                      <Text color="gray.600">No password set</Text>
                    )}
                    <HStack align="center">
                      <Button
                        size="sm"
                        variant="ghost"
                        onClick={handleClickChangePassword}>
                        {authPasswords?.passwordEnabled ? "Change" : "Set"}{" "}
                        password
                      </Button>
                      {authPasswords?.passwordEnabled &&
                        // <Menu size="sm">
                        //   <MenuButton
                        //     size="sm"
                        //     variant="ghost"
                        //     as={IconButton}
                        //     icon={<MoreMenuIcon />}
                        //   />
                        //   <MenuList>
                        //     <MenuItem
                        //       onClick={confirmDeletePasswordModal.onOpen}>
                        //       Remove password
                        //     </MenuItem>
                        //   </MenuList>
                        // </Menu>
                        null}
                    </HStack>
                  </HStack>
                </HStack>
                <HStack w="100%" align="center">
                  <Box w="16rem">
                    <Text>Multifactor</Text>
                  </Box>
                  <HStack
                    align="center"
                    minW="0px"
                    width="100%"
                    justifyContent="space-between">
                    {totpUser?.totpEnabled ? (
                      <>
                        <Text
                          as="span"
                          display="flex"
                          alignItems="center"
                          gap="2">
                          <Icon color="brand.700" as={RiLock2Fill} />
                          Secured
                        </Text>
                        <Menu size="sm">
                          <MenuButton
                            size="sm"
                            variant="ghost"
                            as={IconButton}
                            icon={<MoreMenuIcon />}
                          />
                          <MenuList>
                            <MenuItem onClick={totpUser.disableTOTP}>
                              Disable MFA
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </>
                    ) : (
                      <Button
                        w="100%"
                        size="sm"
                        variant="ghost"
                        onClick={setupMFAModalState.onOpen}>
                        Add multifactor authentication
                      </Button>
                    )}
                  </HStack>
                </HStack>
                {totpUser?.totpEnabled ? (
                  <Text fontSize="sm" color="gray.600">
                    Multifactor Authentication is enabled.
                  </Text>
                ) : (
                  <Text fontSize="sm" color="gray.600">
                    Multifactor Authentication is not enabled.
                  </Text>
                )}
              </VStack>{" "}
              <VStack align="start" w="full" spacing="4">
                <Heading size="sm">Active devices</Heading>
                {sessionsQuery.isLoading ? (
                  <VStack w="full" spacing={4}>
                    <Skeleton h="8" w="20%" />
                    <Skeleton h="6" w="40%" />
                    <Skeleton h="8" w="30%" />
                  </VStack>
                ) : sessionsQuery.isSuccess ? (
                  <VStack w="full" spacing={4} align="start">
                    {sessionsQuery.data.sessions.map((session) => (
                      <HStack
                        align="space-between"
                        spacing="6"
                        w="100%"
                        maxW="72">
                        <HStack align="start" flexGrow="1">
                          <Box>
                            <Icon
                              color="gray.600"
                              boxSize="8"
                              as={RiMacbookLine}
                            />
                          </Box>
                          <Box key={session.sessionID} pt="0.5">
                            <Text>
                              {session.host.deviceType}
                              {session.isCurrent ? (
                                <>
                                  {" "}
                                  <Badge
                                    variant="subtle"
                                    colorScheme="gray"
                                    fontSize="2xs">
                                    This Device
                                  </Badge>
                                </>
                              ) : null}
                            </Text>
                            <Text fontSize="sm" color="gray.600">
                              {session.host.browserName}{" "}
                              {session.host.browserVersion}
                            </Text>
                            <Text fontSize="sm" color="gray.600">
                              {session.host.location}
                            </Text>
                            <Text
                              _firstLetter={{ textTransform: "capitalize" }}
                              fontSize="sm"
                              color="gray.600">
                              {formatDateSinceNow(session.lastActiveDate)}
                            </Text>
                          </Box>
                        </HStack>
                        <Box>
                          {!session.isCurrent && (
                            <Menu size="sm">
                              <MenuButton
                                size="xs"
                                variant="ghost"
                                as={IconButton}
                                icon={<MoreMenuIcon />}
                              />
                              <MenuList>
                                <MenuItem onClick={session.revokeSession}>
                                  Revoke
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          )}
                        </Box>
                      </HStack>
                    ))}
                  </VStack>
                ) : null}
              </VStack>
            </>
          ) : (
            <CenteredAlert w="full" status="error" title="Something went wrong">
              Failed to load user details
            </CenteredAlert>
          )}
        </VStack>
      </VStack>
    </>
  );
}
