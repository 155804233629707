import {
  Button,
  Center,
  ChakraProvider,
  ColorModeScript,
} from "@chakra-ui/react";
import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/plugins.pkgd.min.js";

import "./init"; // must be before react-dates/initialize
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.css";
import "./styles/chakra-date-picker.css";
import initLogging from "./utilities/Logging";

const container = document.getElementById("root");
const root = createRoot(container!);

// If we are on app.complyahoy.com, redirect to app.certcrowd.com (keeping path)
if (window.location.hostname === "app.complyahoy.com") {
  root.render(
    <div style={{ padding: "0.5rem" }}>
      <p>
        We no longer support app.complyahoy.com. Please go to{" "}
        <a href="https://app.certcrowd.com">app.certcrowd.com</a> to access your
        CertCrowd account.
      </p>
      <p>
        If you have any queries or issues, please contact{" "}
        <a href="mailto:help@certcrowd.com">help@certcrowd.com</a> for support.
      </p>
      <div style={{ width: "100%" }}>
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "2rem",
            width: "fit-content",
          }}>
          <a
            style={{
              backgroundColor: "blue",
              borderRadius: "0.5rem",
              padding: "6px 8px",
              color: "white",
              textDecoration: "none",
            }}
            href={`https://app.certcrowd.com${global.location.pathname}${global.location.search}`}>
            Continue to certcrowd.com
          </a>
        </div>
      </div>
    </div>,
  );
} else {
  initLogging();
  root.render(
    <>
      <ColorModeScript initialColorMode="light" />
      <App />
    </>,
  );
}
