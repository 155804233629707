import {
  Box,
  Container,
  ContainerProps,
  Heading,
  HStack,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import Breadcrumbs, {
  BreadcrumbElement,
} from "../../components/chakra/Breadcrumbs";

type Props = {
  children: React.ReactNode;
  breadcrumbs?: BreadcrumbElement[];
  pageTitle?: string;
  size: "full" | "lg" | "md";
  isStatic?: boolean;
};

const ServiceScreenContainer = ({
  children,
  breadcrumbs = [],
  isStatic = false,
  pageTitle,
  size = "lg",
}: Props): JSX.Element => {
  const headingColor = useColorModeValue("gray.700", "whiteAlpha.900");
  const hasAnyHeaderContent = !!pageTitle;
  const contentSize =
    size === "full" ? "full" : size === "lg" ? "container.2xl" : "container.xl";
  return (
    <Box
      w="100vw"
      h="calc(100vh - var(--chakra-space-top-bar))"
      bg="transparent">
      {hasAnyHeaderContent && (
        <Box
          w="100vw"
          pos="fixed"
          bg="white"
          borderBottomWidth="1px"
          borderBottomColor="gray.100"
          top="(var(--chakra-space-top-bar))"
          height="2.5rem"
          zIndex="1">
          <Container
            w="auto"
            transition="width 1s linear"
            h="100%"
            maxW={contentSize}>
            <HStack h="100%" w="100%" align="center" gap="0">
              <Breadcrumbs breadcrumbs={breadcrumbs} />
              <Heading color={headingColor} size="md">
                {pageTitle}
              </Heading>
            </HStack>
          </Container>
        </Box>
      )}
      {/** If the page is 'static' then we want the content to fill rest of screen but not scroll, and to hide overflow */}
      {isStatic ? (
        <Box
          w="100%"
          pt={hasAnyHeaderContent ? "2.5rem" : "0"}
          h="calc(100vh - var(--chakra-space-top-bar))"
          overflow="hidden">
          <Container
            w="100%"
            transition="width 1s linear"
            h="100%"
            maxW={contentSize}>
            {children}
          </Container>
        </Box>
      ) : (
        <Box pt="2.5rem">
          <Container h="100%" maxW={contentSize}>
            <Box>{children}</Box>
          </Container>
        </Box>
      )}
    </Box>
  );
};

export default ServiceScreenContainer;
