import {
  Badge,
  Button,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  LinkBox,
  LinkOverlay,
  SimpleGrid,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  chakra,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { skipToken } from "@tanstack/react-query";
import { Link as RouterLink, useParams } from "react-router-dom";
import { RefreshIcon } from "../../constants/commonIcons";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import {
  UserAccountStatus,
  UserAccountStatusIDToName,
} from "../../shared/v2/constants/UserAccountStatusID";
import { AdminPortalUser } from "../../shared/v2/definitions/admin.types";
import { api } from "../../utilities/apiRequest";
import {
  formatDateSinceNow,
  formatDateString,
  formatUtcDateReadableLong,
} from "../../utilities/dateUtils";
import useToast from "../../utilities/useToast";
import NotFoundScreen from "../NotFound";
import { ResetUserPasswordModal } from "./components/ResetUserPasswordModal";
import { ServiceDataSectionCard } from "./components/ServiceDataSectionCard";
import { ServiceHistoryTimelineItem } from "./components/ServiceHistoryTimelineItem";
import { ServiceUserDetailsCard } from "./components/serviceUserCards/ServiceUserDetailsCard";
import { ServiceUserEmailsCard } from "./components/serviceUserCards/ServiceUserEmailsCard";
import { ServiceUserSecurityCard } from "./components/serviceUserCards/ServiceUserSecurityCard";
import { ServiceUserSSOCard } from "./components/serviceUserCards/ServiceUserSSOCard";
import { ServiceUserData } from "./components/ServiceUserData";
import ServiceScreenContainer from "./ServiceScreenContainer";

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceUserScreen({ servicePermissions }: Props) {
  const { userID } = useParams();

  const passwordResetModalState = useDisclosure({ defaultIsOpen: false });

  const { displayToast } = useToast();
  const userModalState = useDisclosure();

  const apiUtils = api.useUtils();
  const userQuery = api.admin.user.getByID.useQuery(
    userID
      ? {
          userID,
        }
      : skipToken,
  );
  const historyQuery = api.admin.history.list.useQuery(
    userID
      ? {
          targetUserID: userID,
        }
      : skipToken,
  );
  const markAsInternalMutation = api.admin.user.markInternal.useMutation();
  const clearMfaMutation = api.admin.user.clearMfa.useMutation();

  const anyIsFetching =
    userQuery.isFetching ||
    markAsInternalMutation.isPending ||
    clearMfaMutation.isPending;

  const handleClickRefresh = () => {
    apiUtils.admin.user.getByID.invalidate();
    apiUtils.admin.history.list.invalidate();
  };

  const userAccounts = userQuery.isSuccess
    ? userQuery.data.user.userAccounts
    : [];
  const sortbyAccountName = (
    a: AdminPortalUser["userAccounts"][number],
    b: AdminPortalUser["userAccounts"][number],
  ) => a.account.name?.localeCompare(b.account.name);
  userAccounts?.sort(sortbyAccountName);

  const handleClickResetPassword = async () => {
    passwordResetModalState.onOpen();
  };
  const handleClickClearMFA = async () => {
    try {
      if (!userQuery.isSuccess) {
        return;
      }

      await clearMfaMutation.mutateAsync({
        userID: userQuery.data.user.userID,
      });

      displayToast({
        title: "Success",
        description: "MFA Cleared",
        status: "success",
      });

      apiUtils.admin.user.getByID.invalidate();
      apiUtils.admin.history.list.invalidate();
    } catch (err) {
      displayToast({
        title: "Error",
        description: `Error clearing MFA: ${err.m}`,
        status: "error",
      });
      userModalState.onClose();
    }
  };
  const handleClickMarkInternal = async () => {
    if (!userQuery.isSuccess) {
      return;
    }
    const { user } = userQuery.data;
    try {
      apiUtils.admin.user.getByID.setData(
        {
          userID: user.userID || "",
        },
        {
          ...userQuery.data,
          user: {
            ...user,
            isInternal: !user.isInternal,
          },
        },
      );
      const result = await markAsInternalMutation.mutateAsync({
        userID: user.userID,
        isInternal: !user.isInternal,
      });
      displayToast({
        title: "Success",
        description: "User has been updated",
        status: "success",
      });
      if (result.user) {
        apiUtils.admin.user.getByID.setData(
          {
            userID: user.userID || "",
          },
          {
            ...userQuery.data,
            user: result.user,
          },
        );
      } else {
        apiUtils.admin.user.getByID.invalidate();
      }
      apiUtils.admin.history.list.invalidate();
    } catch (err) {
      displayToast({
        title: "Error",
        description: `Error updating user`,
        status: "error",
      });
      apiUtils.admin.user.getByID.invalidate();
      apiUtils.admin.history.list.invalidate();
    }
  };


  if (!servicePermissions["users:manage"]) {
    return <NotFoundScreen />;
  }

  const pageTitle = userQuery.isSuccess
    ? `${userQuery.data.user.firstName} ${userQuery.data.user.lastName}`
    : "Manage User";

  return (
    <chakra-scope>
      <ServiceScreenContainer
        size="md"
        pageTitle={pageTitle}
        breadcrumbs={[
          { label: "Service Portal", link: "/_ccserviceportal" },
          { label: "Manage Users", link: "/_ccserviceportal/users" },
        ]}>
        {userQuery.isLoading && (
          <SimpleGrid columns={2} spacing={12}>
            <VStack w="full" spacing={4}>
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
              <Skeleton h={6} w="90%" />
            </VStack>
            <VStack w="full" spacing={4}>
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
              <Skeleton h={4} w="70%" maxW="14rem" />
            </VStack>
          </SimpleGrid>
        )}
        {userQuery.isSuccess && (
          <VStack pt="4" pb="4" w="100%">
            <Grid w="100%" templateColumns="minmax(65%, 1fr) 1fr;" gap="10">
              <GridItem>
                <VStack flexGrow="1" spacing="4">
                  <ServiceUserDetailsCard
                    isFetching={anyIsFetching}
                    user={userQuery.data.user}
                    onClickSetAsInternal={handleClickMarkInternal}
                  />
                  <ServiceUserEmailsCard
                    isFetching={anyIsFetching}
                    user={userQuery.data.user}
                  />
                  <ServiceUserSecurityCard
                    isFetching={anyIsFetching}
                    userAuth={userQuery.data.user.auth}
                    onClickClearMfa={handleClickClearMFA}
                    onClickResetPassword={handleClickResetPassword}
                  />
                  <ServiceUserSSOCard
                    isFetching={anyIsFetching}
                    userAuth={userQuery.data.user.auth}
                  />
                  <ServiceDataSectionCard title="Accounts">
                    <TableContainer>
                      <Table size="sm" colorScheme="gray" variant="striped">
                        <Thead position="relative">
                          <Tr>
                            <Th>Account name</Th>
                            <Th>System name</Th>
                            <Th>Account enabled??</Th>
                            <Th>Status</Th>
                            <Th>Added on</Th>
                          </Tr>
                        </Thead>
                        <Tbody
                          opacity={
                            userQuery.isLoading || userQuery.isFetching
                              ? 0.4
                              : 1
                          }>
                          {userQuery.data.user.userAccounts.map((u) => (
                            <LinkBox as={Tr} key={u.account.accountID}>
                              <Td display="flex">
                                <LinkOverlay
                                  ps="2"
                                  as={RouterLink}
                                  flexDir="column"
                                  alignItems="start"
                                  to={`/_ccserviceportal/accounts/${u.account.accountID}`}>
                                  <Flex
                                    flexDir="column"
                                    align="start"
                                    as="span">
                                    <chakra.span>
                                      {" "}
                                      <Badge colorScheme="gray">
                                        {" "}
                                        {u.account.accountID}{" "}
                                      </Badge>
                                    </chakra.span>
                                    <Text>{u.account.name}</Text>
                                  </Flex>
                                </LinkOverlay>
                              </Td>
                              <Td>{u.account.systemName}</Td>
                              <Td>
                                {u.account.isActive ? "Enabled" : "Disabled"}
                              </Td>
                              <Td>
                                {
                                  UserAccountStatusIDToName[
                                    u.userAccountStatusID as UserAccountStatus
                                  ]
                                }
                              </Td>
                              <Td>
                                {formatUtcDateReadableLong(
                                  new Date(u.modifiedTs),
                                )}
                              </Td>
                            </LinkBox>
                          ))}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </ServiceDataSectionCard>
                </VStack>
              </GridItem>
              <GridItem>
                <VStack
                  position="sticky"
                  top="calc(var(--chakra-space-top-bar) + 2.5rem)"
                  flexGrow="1"
                  align="start"
                  justify="start">
                  <Button
                    alignSelf="end"
                    isLoading={userQuery.isFetching}
                    onClick={handleClickRefresh}
                    leftIcon={<Icon as={RefreshIcon} />}
                    variant="ghost"
                    loadingText="Refreshing"
                    size="sm">
                    Refresh
                  </Button>
                  <ServiceUserData
                    label="DB ID"
                    value={userQuery.data.user.userID}
                  />
                  <Divider />
                  <ServiceUserData
                    label="Clerk ID"
                    value={userQuery.data.user.auth.authServiceID}
                  />
                  <Divider />
                  <ServiceUserData
                    capitalise
                    label="Last active"
                    value={
                      userQuery.data.user.auth.lastActiveTs
                        ? formatDateSinceNow(
                            new Date(userQuery.data.user.auth.lastActiveTs),
                          )
                        : "Never"
                    }
                  />
                  <Divider />

                  <ServiceUserData
                    label="User since"
                    value={
                      userQuery.data.user.createTs
                        ? formatDateString(
                            new Date(userQuery.data.user.createTs),
                          )
                        : "Never"
                    }
                  />
                  <Divider />
                  <ServiceUserData
                    label="Recent account"
                    value={
                      userQuery.data.user.userAccounts.find(
                        (ua) =>
                          ua.account.accountID ===
                          userQuery.data.user.auth.publicMetadata.lastAccountID,
                      )?.account.name || "Unknown"
                    }
                    link={{
                      to: `/_ccserviceportal/account/${userQuery.data.user.auth.publicMetadata.lastAccountID}`,
                      ariaLabel: "Go to account",
                    }}
                    extra={
                      userQuery.data.user.auth.publicMetadata
                        .lastAccountID as string
                    }
                  />
                  <Divider />
                  <Heading size="sm">Service history</Heading>
                  <VStack spacing="0" align="start">
                    {historyQuery.isSuccess &&
                      historyQuery.data.history.map((h) => (
                        <ServiceHistoryTimelineItem
                          key={h.serviceHistoryID}
                          history={h}
                        />
                      ))}
                    {historyQuery.isLoading && (
                      <>
                        <Skeleton h={6} w="90%" />
                        <Skeleton h={6} w="90%" />
                        <Skeleton h={6} w="90%" />
                        <Skeleton h={6} w="70%" />
                        <Skeleton h={6} w="80%" />
                      </>
                    )}
                  </VStack>
                </VStack>
              </GridItem>
            </Grid>
          </VStack>
        )}
        {userQuery.isSuccess && (
          <ResetUserPasswordModal
            user={userQuery.data.user}
            isOpen={passwordResetModalState.isOpen}
            onClose={passwordResetModalState.onClose}
          />
        )}
        {/* <VStack w="full" align="start">
          <HStack w="full" align="start" spacing="4">
            {isLoading ? (
              <VStack w="full" spacing={4}>
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
                <Skeleton h={6} w="90%" />
              </VStack>
            ) : (
              <TableContainer w="full">
                <Table size="sm" colorScheme="gray" variant="striped" w="full">
                  <Tbody>
                    {dataToDisplay.map(({ label, key }) => (
                      <Tr key={key} opacity={isFetching ? 0.4 : undefined}>
                        <Td fontWeight="bold">{label}</Td>
                        <Td>{getData(key)}</Td>
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <VStack align="start" w="full">
              <Heading size="sm">Actions</Heading>
              <ButtonGroup
                isDisabled={isFetching}
                isAttached
                orientation="vertical"
                variant="outline">
                <Tooltip
                  label={
                    usersDetails?.mfa
                      ? "Reset users multi factor authentication"
                      : "User does not have multi factor authentication setup"
                  }>
                  <Button
                    isDisabled={!usersDetails?.mfa || isFetching}
                    isLoading={clearMFA.isLoading}
                    onClick={userModalState.onOpen}>
                    Clear MFA
                  </Button>
                </Tooltip>

                <Button
                  isLoading={resetPassword.isLoading}
                  isDisabled={isFetching}
                  onClick={passwordModalState.onOpen}>
                  Send Password Reset
                </Button>
                <Button
                  isLoading={makeInternal.isLoading}
                  isDisabled={isFetching}
                  onClick={handleClickMarkInternal}>
                  Mark as {usersDetails?.isInternal ? "External" : "Internal"}
                </Button>
              </ButtonGroup>
            </VStack>
          </HStack>
        </VStack> */}

        {/* <VStack paddingTop="28">
          <TableContainer w="full">
            <Heading size="md">Accounts</Heading>
            <Table size="sm" colorScheme="gray" variant="striped">
              <Thead position="relative">
                <Tr>
                  <Th>Account Name</Th>
                  <Th>System Name</Th>
                  <Th>Status</Th>
                  <Th>Last Modified</Th>
                  <Th>Created</Th>
                </Tr>
              </Thead>

              {isLoading && (
                <Tbody>
                  <Tr>
                    <Spinner
                      zIndex={1}
                      opacity={1}
                      size="lg"
                      position="absolute"
                      left="50%"
                    />
                  </Tr>
                </Tbody>
              )}
              {userQuery.isSuccess && (
                <Tbody
                  opacity={
                    userQuery.isLoading || userQuery.isFetching ? 0.4 : 1
                  }>
                  {usersDetails?.userAccounts.map((u) => (
                    <LinkBox as={Tr} key={u.accountID}>
                      <Td display="flex">
                        <LinkOverlay
                          ps="2"
                          as={RouterLink}
                          flexDir="column"
                          alignItems="start"
                          to={`/_ccserviceportal/account/${u.accountID}`}>
                          <Flex flexDir="column" align="start" as="span">
                            <chakra.span>
                              {" "}
                              <Badge colorScheme="gray"> {u.accountID} </Badge>
                            </chakra.span>
                            <Text>{u.accountName}</Text>
                          </Flex>
                        </LinkOverlay>
                      </Td>
                      <Td>{u.accountSystemName}</Td>
                      <Td>{UserAccountStatusIDToName[u.accountStatus]}</Td>
                      <Td>
                        {formatUtcDateReadableLong(new Date(u.modifiedTs))}
                      </Td>
                      <Td>
                        {formatUtcDateReadableLong(new Date(u.createdTs))}
                      </Td>
                    </LinkBox>
                  ))}
                </Tbody>
              )}
            </Table>
          </TableContainer>
        </VStack>

        <Modal
          motionPreset="slideInBottom"
          size="2xl"
          isOpen={userModalState.isOpen}
          onClose={userModalState.onClose}>
          <ModalOverlay />

          <ModalContent>
            <chakra-scope>
              <ModalHeader>Confirm MFA Reset</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text>
                  Are you sure you want to reset{" "}
                  <strong>
                    <i>
                      {usersDetails?.firstName} {usersDetails?.lastName}{" "}
                    </i>
                  </strong>
                  multifactor authentication?
                </Text>
              </ModalBody>

              <ModalFooter>
                <Button
                  colorScheme="brand"
                  mr={3}
                  onClick={userModalState.onClose}>
                  Close
                </Button>
                <Button
                  variant="ghost"
                  isDisabled={clearMFA.isLoading}
                  isLoading={clearMFA.isLoading}
                  onClick={handleClickClearMFA}>
                  Confirm
                </Button>
              </ModalFooter>
            </chakra-scope>
          </ModalContent>
        </Modal>
        <Modal
          motionPreset="slideInBottom"
          size="2xl"
          isOpen={passwordModalState.isOpen}
          onClose={passwordModalState.onClose}>
          <ModalOverlay />

          <ModalContent>
            <chakra-scope>
              <ModalHeader>Confirm Password Reset</ModalHeader>
              <ModalCloseButton
                onClick={async () => {
                  setTempPassword(null);
                  passwordModalState.onClose();
                }}
              />
              <ModalBody>
                {tempPassword === null ? (
                  <>
                    {" "}
                    <Text>
                      You are about to reset{" "}
                      <strong>
                        <i>
                          {usersDetails?.firstName} {usersDetails?.lastName}{" "}
                        </i>
                      </strong>
                      password
                    </Text>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="isEmailed" mb="0">
                        Email password to user?
                      </FormLabel>
                      <Switch
                        id="email-alerts"
                        isChecked={isEmailChecked}
                        onChange={(e) => setIsEmailChecked(e.target.checked)}
                      />
                    </FormControl>
                    <FormControl display="flex" alignItems="center">
                      <FormLabel htmlFor="email-alerts" mb="0">
                        Show me the password?
                      </FormLabel>
                      <Switch
                        id="isShown"
                        isChecked={isShowChecked}
                        onChange={(e) => setIsShowChecked(e.target.checked)}
                      />
                    </FormControl>
                    <ModalFooter>
                      <Button
                        colorScheme="gray"
                        mr={3}
                        isDisabled={resetPassword.isLoading}
                        onClick={passwordModalState.onClose}>
                        Close
                      </Button>
                      <Tooltip
                        label={isShowChecked || isEmailChecked ? null : "👉👈"}>
                        <Button
                          isDisabled={!(isShowChecked || isEmailChecked)}
                          isLoading={resetPassword.isLoading}
                          onClick={handleClickResetPassword}>
                          Reset
                        </Button>
                      </Tooltip>
                    </ModalFooter>
                  </>
                ) : (
                  <>
                    <InputGroup>
                      <Input
                        value={value}
                        pr="10"
                        isReadOnly
                        onChange={(e) => {
                          setValue(e.target.value);
                        }}
                      />
                      <InputRightElement width="10">
                        <IconButton
                          variant="outline"
                          colorScheme="gray"
                          aria-label="Copy to clipboard"
                          icon={<Icon as={CopyToClipboardIcon} />}
                          onClick={onCopy}
                        />
                      </InputRightElement>
                    </InputGroup>
                    <ModalFooter>
                      <Button
                        onClick={async () => {
                          setTempPassword(null);
                          passwordModalState.onClose();
                        }}>
                        Finish
                      </Button>
                    </ModalFooter>
                  </>
                )}
              </ModalBody>
            </chakra-scope>
          </ModalContent>
        </Modal> */}
      </ServiceScreenContainer>
    </chakra-scope>
  );
}
