import { BoxProps, Skeleton, VStack } from "@chakra-ui/react";
import {
  Card,
  CardHeading,
  CardContent,
} from "../../../components/chakra/card";

type Props = {
  children?: React.ReactNode;
  title?: string;
  actions?: React.ReactNode;
} & BoxProps;

export function ServiceDataSectionCard({
  children,
  title,
  actions,
  ...otherProps
}: Props) {
  return (
    <Card w="100%" variant="solid" {...otherProps}>
      <CardHeading title={title || ""}>{actions}</CardHeading>
      <CardContent>{children}</CardContent>
    </Card>
  );
}
