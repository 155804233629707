import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Flex,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  chakra,
} from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { RiExpandLeftRightLine, RiFilter3Fill } from "react-icons/ri";
import { useQueryClient } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import DebouncedInput from "../../components/DebouncedInput";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import Spinner from "../../components/chakra/common/Spinner";
import {
  DropdownIcon,
  InfoIcon,
  RefreshIcon,
  WarningIcon,
} from "../../constants/commonIcons";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";
import { GlobalAccountSummary } from "../../shared/v2/definitions/accounts";
import { useApiQuery } from "../../utilities/apibelRequest";
import NotFoundScreen from "../NotFound";
import {
  parseAsInteger,
  parseAsString,
  parseAsStringLiteral,
  useQueryState,
} from "nuqs";
import { api } from "../../utilities/apiRequest";
import ServiceScreenContainer from "./ServiceScreenContainer";
import { useScreenExpansion } from "./useScreenExpansion";

const filterTypes = ["All", "Is demo", "Is blueprint"] as const;

type FilterTypes = (typeof filterTypes)[number];

export const TagColorSchemMap = {
  demo: "pink",
  blueprint: "blue",
} as const;

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServiceAccountsScreen({ servicePermissions }: Props) {
  // Query params
  const apiUtils = api.useUtils();
  const [page, setPage] = useQueryState("page", parseAsInteger.withDefault(0));
  const [filter, setFilter] = useQueryState(
    "filter",
    parseAsStringLiteral(filterTypes).withDefault("All"),
  );
  const [query, setQuery] = useQueryState("query");

  const [isScreenExpanded, setIsScreenExpanded] = useScreenExpansion();

  const onClickExpandTable = () => {
    setIsScreenExpanded(!isScreenExpanded);
  };

  const accountsQuery = api.admin.account.list.useQuery({
    pagination: {
      page,
    },
    filter: {
      name: query ?? undefined,
      isDemo: filter === "Is demo" ? true : undefined,
      isBlueprint: filter === "Is blueprint" ? true : undefined,
    },
  });

  const handleQueryChange = useCallback(
    (value?: string) => {
      setPage(0);
      setQuery(value || null);
    },
    [setQuery, setPage],
  );

  const handleFilterChange = useCallback(
    (value?: FilterTypes) => {
      setPage(0);
      setFilter(value || null);
    },
    [setPage, setFilter],
  );

  if (!servicePermissions["accounts:read"]) {
    return <NotFoundScreen />;
  }

  const handleClickRefresh = () => {
    apiUtils.admin.account.list.invalidate();
  };

  const renderPaginator = () => {
    const hasNext =
      !accountsQuery.isFetching && accountsQuery.data?.pagination.hasNext;
    const hasPrev = !accountsQuery.isFetching && page > 0;
    return (
      <>
        <Button
          onClick={() => {
            if (!accountsQuery.data) return;
            const toPage = accountsQuery.data.pagination.page - 1;
            setPage(toPage);
          }}
          isDisabled={!hasPrev}>
          Prev
        </Button>
        <Button
          onClick={() => {
            if (!accountsQuery.data) return;
            const toPage = accountsQuery.data.pagination.page + 1;
            setPage(toPage);
          }}
          isDisabled={!hasNext}>
          Next
        </Button>
      </>
    );
  };

  const renderSearch = () => {
    return (
      <HStack w="100%" justify="space-between" align="end">
        <Flex gap={4} alignItems="end">
          <Box minW="15rem">
            <DebouncedInput
              onChange={handleQueryChange}
              value={query || undefined}
              placeholder="Search by account name"
            />
          </Box>
          <div>
            <Menu size="sm">
              <MenuButton
                disabled={accountsQuery.isFetching}
                as={Button}
                variant="ghost"
                size="sm"
                leftIcon={<Icon as={RiFilter3Fill} />}
                rightIcon={<Icon as={DropdownIcon} />}>
                {filter === "All" ? "Filter" : `Filtered by ${filter}`}
              </MenuButton>
              <MenuList zIndex="99">
                <MenuOptionGroup value={filter} type="radio">
                  {filterTypes.map((item) => (
                    <MenuItemOption
                      key={item}
                      value={item}
                      onClick={() => handleFilterChange(item)}>
                      {item}
                    </MenuItemOption>
                  ))}
                </MenuOptionGroup>
              </MenuList>
            </Menu>
          </div>
        </Flex>
        <Box>
          <Button
            isLoading={accountsQuery.isRefetching}
            disabled={accountsQuery.isFetching}
            onClick={handleClickRefresh}
            leftIcon={<Icon as={RefreshIcon} />}
            variant="ghost"
            loadingText="Refreshing"
            size="sm">
            Refresh
          </Button>
          <IconButton
            onClick={onClickExpandTable}
            size="md"
            variant="ghost"
            aria-label="Expand/Collapse table"
            icon={<RiExpandLeftRightLine />}
          />
        </Box>
      </HStack>
    );
  };

  return (
    <ServiceScreenContainer
      isStatic
      pageTitle="Manage Accounts"
      size={isScreenExpanded ? "full" : "lg"}
      breadcrumbs={[{ label: "Service Portal", link: "/_ccserviceportal" }]}>
      <VStack align="start" w="100%" h="100%" py="2">
        {renderSearch()}
        <TableContainer
          flexGrow="1"
          h="100%"
          w="full"
          overflowY="auto"
          borderRadius="lg"
          borderStyle="solid"
          borderWidth="1px">
          <Table
            isFetching={accountsQuery.isFetching}
            variant="data-table"
            size="sm"
            position="relative"
            sx={{
              "& thead tr th": {
                paddingTop: 2,
                paddingBottom: 2,
                position: "sticky",
                top: 0,
                backgroundColor: "white",
              },
            }}>
            <Thead zIndex="4" position="relative" maxH="fit-content">
              <Tr>
                <Th>Account</Th>
                <Th>System Name</Th>
                <Th>Account Owner</Th>
                <Th>Account Status</Th>
                <Th>Active Users & License</Th>
                <Th>Internal Users</Th>
                <Th>Create time</Th>
                <Th>Account Tags</Th>
              </Tr>
            </Thead>
            {accountsQuery.isLoading && (
              <Tbody h="100%">
                <Tr h="100%">
                  <Td colSpan={8}>
                    <Flex
                      justifyContent="center"
                      alignItems="start"
                      justify="center"
                      h="100%">
                      <Flex h="70%" alignItems="center" justify="center">
                        <Spinner size="xl" />
                      </Flex>
                    </Flex>
                  </Td>
                </Tr>
              </Tbody>
            )}

            {accountsQuery.isSuccess && (
              <Tbody>
                {accountsQuery.data.accounts.map((account) => (
                  <Tr
                    key={account.accountID}
                    maxH="fit-content"
                    opacity={account.accountStatus === "active" ? 1 : 0.6}>
                    <LinkBox
                      _hover={{ textDecor: "underline" }}
                      as={Td}
                      maxW="20rem"
                      overflowX="hidden">
                      <LinkOverlay
                        ps="2"
                        as={RouterLink}
                        display="flex"
                        flexDir="column"
                        alignItems="start"
                        to={`/_ccserviceportal/accounts/${account.accountID}`}>
                        <Flex flexDir="column" align="start" as="span">
                          <chakra.span>
                            {" "}
                            <Badge colorScheme="gray">
                              {" "}
                              {account.accountID}{" "}
                            </Badge>
                          </chakra.span>
                          <Text
                            fontWeight="700"
                            color={
                              account.license.licenseStatus !== "ok"
                                ? "red"
                                : undefined
                            }>
                            {account.accountName}
                          </Text>
                        </Flex>
                      </LinkOverlay>
                    </LinkBox>
                    <Td>
                      {account.systemName ? (
                        account.systemName
                      ) : (
                        <chakra.span fontStyle="italic" color="gray.400">
                          -
                        </chakra.span>
                      )}
                    </Td>
                    <Td>
                      <VStack align="start">
                        <Box>
                          {account.accountOwner.firstName}{" "}
                          {account.accountOwner.lastName}
                        </Box>
                        <Text size="sm" color="gray.600">
                          {account.accountOwner.email}
                        </Text>
                      </VStack>
                    </Td>
                    <Td>
                      <Badge
                        colorScheme={
                          account.accountStatus === "active" ? "brand" : "gray"
                        }>
                        {account.accountStatus}
                      </Badge>
                    </Td>
                    <Td>
                      <VStack align="start">
                        <Box>
                          <Badge
                            colorScheme={
                              account.license.licenseStatus === "ok"
                                ? "teal"
                                : "red"
                            }>
                            {account.license.licenseStatus}
                          </Badge>
                        </Box>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="0.5"
                          color={
                            account.license.licenseStatus !== "ok"
                              ? "red.700"
                              : undefined
                          }>
                          <span>
                            <Box as="span" fontWeight="bold">
                              {account.userCounts.activeUsers}
                            </Box>{" "}
                            of{" "}
                            {account.license.userLicenses === null
                              ? "20"
                              : account.license.userLicenses <= 0
                              ? "infinite"
                              : account.license.userLicenses}
                          </span>
                          <Tooltip
                            label={
                              account.userCounts.pendingUsers
                                ? `+ ${account.userCounts.pendingUsers} pending users`
                                : "No pending users"
                            }>
                            <span>
                              <Icon
                                boxSize={4}
                                color="brand.700"
                                as={InfoIcon}
                              />
                            </span>
                          </Tooltip>
                          {account.license.licenseType === "default" && (
                            <Tooltip
                              label="No license set - Assuming '20'"
                              shouldWrapChildren>
                              <Icon
                                color="yellow.700"
                                boxSize="4"
                                as={WarningIcon}
                              />
                            </Tooltip>
                          )}
                        </Box>
                      </VStack>
                    </Td>
                    <Td>{account.userCounts.internalUsers}</Td>
                    <Td>{new Date(account.createTs).toLocaleString()}</Td>
                    <Td>
                      <HStack as="span">
                        {account.tags.map((tag) => (
                          <Badge colorScheme={TagColorSchemMap[tag]} key={tag}>
                            {tag}
                          </Badge>
                        ))}
                      </HStack>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            )}
          </Table>
        </TableContainer>
        <HStack justify="end" w="100%">
          <ButtonGroup size="sm" variant="outline" isAttached>
            {renderPaginator()}
          </ButtonGroup>
        </HStack>
      </VStack>
    </ServiceScreenContainer>
  );
}
