import {
  Avatar,
  Box,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Heading,
  HStack,
  Icon,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { EditorContent } from "@tiptap/react";
import { formatDistance } from "date-fns";
import { useEffect, useState } from "react";
import { RiArrowRightLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import {
  ManualData,
  ManualSectionData,
  ManualSectionSummary,
} from "../../../../shared/v2/definitions/manuals";
import { useApiQuery } from "../../../../utilities/apibelRequest";
import { formatUtcDateReadableLong } from "../../../../utilities/dateUtils";
import { useEditorWithExtensions } from "./useEditorWithExtensions";

type Props = {
  manual: ManualData & { sections: ManualSectionSummary[] };
};

export function ManualCard({ manual }: Props) {
  const sectionsQuery = useApiQuery("manual/manySections", {
    manualID: manual.manualID,
    contentType: "full",
    limit: 1,
  });

  const readonlyEditor = useEditorWithExtensions({
    content: undefined,
    editable: false,
    placeholder: "No content",
    limit: 20000,
  });

  useEffect(() => {
    console.log("update content");
    const sections = sectionsQuery.data?.sections || [];
    sections.sort((a, b) => a.order - b.order);
    const firstSect = sections[0];
    const sectionJson = firstSect?.content.jsonStr;
    console.log({ sectionJson });
    readonlyEditor?.commands.setContent(
      sectionJson && sectionJson.length >= 1
        ? JSON.parse(sectionJson)
        : undefined,
    );
  }, [sectionsQuery.data, readonlyEditor]);

  return (
    <LinkBox
      _hover={{
        boxShadow: "sm",
        borderColor: "brand.500",
        "& .arrow-icon": {
          transform: "translate(3px, 0)",
          color: "brand.500",
          opacity: 1.0,
        },
        "& .heading-text": {
          color: "brand.500",
        },
      }}
      as={Card}
      variant="outline">
      <CardHeader>
        <LinkOverlay
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          as={Link}
          to={`/manual/${manual.manualID}`}>
          <Heading
            transitionProperty="common"
            transitionDuration="fast"
            className="heading-text"
            size="md"
            alignItems="center"
            display="flex">
            {manual.name}
          </Heading>
          <Icon
            transitionProperty="common"
            transitionDuration="fast"
            color="black"
            opacity="0.0"
            ml="1"
            boxSize="5"
            transition=""
            className="arrow-icon"
            as={RiArrowRightLine}
          />
        </LinkOverlay>
      </CardHeader>
      <CardBody>
        <VStack w="full" align="start" spacing="1">
          {sectionsQuery.isSuccess ? (
            !sectionsQuery.data.sections[0] ||
            !sectionsQuery.data?.sections[0]?.content.jsonStr ? (
              <Text fontStyle="italic" color="gray.600">
                No content
              </Text>
            ) : (
              <Box
                position="relative"
                w="100%"
                _after={{
                  content: "''",
                  position: "absolute",
                  left: 0,
                  right: 0,
                  bottom: 0,
                  height: "25%",
                  background:
                    "linear-gradient(180deg, rgba(100,100,100,0) 0%, rgba(255,255,255,1) 100%)",
                  pointerEvents: "none",
                }}>
                <Box
                  maxH="10rem"
                  w="100%"
                  overflowY="hidden"
                  px="0.5"
                  py="0.5"
                  fontSize="0.75rem"
                  className="manual-editor">
                  <EditorContent editor={readonlyEditor} />
                </Box>
              </Box>
            )
          ) : (
            <>
              <Skeleton height="1rem" width="70%" />
              <Skeleton height="1rem" width="70%" />
              <Skeleton height="1rem" width="85%" />
              <Skeleton height="1rem" width="80%" />
            </>
          )}
        </VStack>
      </CardBody>
      <CardFooter>
        <HStack zIndex={1} justifyContent="end" w="full" gap="4">
          <Tooltip
            shouldWrapChildren
            label={`Owned by ${manual.responsibleUser.firstName} ${manual.responsibleUser.lastName}`}>
            <Avatar
              size="xs"
              name={`${manual.responsibleUser.firstName} ${manual.responsibleUser.lastName}`}
            />
          </Tooltip>
          <Divider orientation="vertical" />
          <Tooltip
            shouldWrapChildren
            label={`Updated ${formatUtcDateReadableLong(
              new Date(manual.modifiedTs),
            )}`}>
            <Text fontSize="xs" color="gray.500">
              Last Updated{" "}
              {formatDistance(new Date(manual.modifiedTs), Date.now())} ago
            </Text>
          </Tooltip>
        </HStack>
      </CardFooter>
    </LinkBox>
  );
}
