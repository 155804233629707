import { Avatar, Box, Flex, Tooltip } from "@chakra-ui/react";
import {
  AdminPortalHistory,
  EventChangeUserPassword,
  EventMarkAccountActive,
  EventMarkInternal,
  EventUpdateAccountActive,
  EventUpdateAccountName,
  EventUpdateAccountTags,
  ServiceHistoryEventBase,
} from "../../../shared/v2/definitions/admin-service-event.types";
import { formatDateSinceNow } from "../../../utilities/dateUtils";

type ServiceHistoryTimelineItemProps = {
  history: AdminPortalHistory;
};

function eventTypeCodeToText(
  historyEvent: ServiceHistoryEventBase,
): [string, string[]] {
  switch (historyEvent.eventType) {
    case "change-password": {
      const changePasswordExtras: string[] = [];
      const event = historyEvent as EventChangeUserPassword;
      if (event.eventDetails.emailedPassword) {
        changePasswordExtras.push("notified user");
      }
      if (event.eventDetails.shownPassword) {
        changePasswordExtras.push("shown password");
      }
      return ["reset password", changePasswordExtras];
    }
    case "clear-mfa":
      return ["cleared mfa", []];
    case "mark-internal": {
      const event = historyEvent as EventMarkInternal;
      return [
        event.eventDetails.internal
          ? "set user as internal"
          : "set user as not internal",
        [],
      ];
    }

    case "update-account-active": {
      const event = historyEvent as EventUpdateAccountActive;
      return [
        event.eventDetails.isAccountEnabled
          ? "enabled account"
          : "disabled account",
        [],
      ];
    }
    case "update-account-name": {
      const event = historyEvent as EventUpdateAccountName;
      return ["updated account name", [`To: ${event.eventDetails.name}`]];
    }

    case "account-delete": {
      return ["marked account for deletion", []];
    }

    case "account-restore": {
      return ["restored account", []];
    }

    case "update-account-tags": {
      const event = historyEvent as EventUpdateAccountTags;
      const adds: string[] = [];
      const removes: string[] = [];
      Object.entries(event.eventDetails.tags).forEach(([tag, action]) => {
        if (action) {
          adds.push(tag);
        } else {
          removes.push(tag);
        }
      });
      const addText = adds.length > 0 ? `Added: ${adds.join(", ")}` : "";
      const removeText =
        removes.length > 0 ? `Removed: ${removes.join(", ")}` : "";
      return [
        "updated account tags",
        [[addText, removeText].join(", ").trim()],
      ]
    }

    case "mark-account-active": {
      const event = historyEvent as EventMarkAccountActive;
      return [
        `set account as ${event.eventDetails.active ? "active" : "inactive"}`,
        [],
      ];
    }

    default:
      return ["unknown event", []];
  }
}

export function ServiceHistoryTimelineItem({
  history,
}: ServiceHistoryTimelineItemProps) {
  const evText = eventTypeCodeToText(history.event);
  return (
    <Flex key={history.serviceHistoryID} shrink="0" alignItems="start" gap="4">
      <Box alignSelf="stretch" pos="relative">
        <Box
          pos="absolute"
          borderInlineStartWidth="1px"
          marginInlineStart="calc(-0.5px)"
          insetInlineStart="calc(1rem / 2)"
          insetBlock="0"
          borderColor="gray.300"
        />
        <Tooltip
          label={`Event caused by ${history.actorUser.firstName} ${history.actorUser.lastName}`}>
          <Avatar
            size="2xs"
            bg="brand.800"
            name={`${history.actorUser.firstName} ${history.actorUser.lastName}`}
          />
        </Tooltip>
      </Box>
      <Flex pb="4" w="full" flexDirection="column" gap="1">
        <Box>
          <Box fontSize="sm" lineHeight="1rem">
            {history.actorUser.firstName} {evText[0]}
          </Box>
          <Box fontStyle="italic" color="gray.600" fontSize="xs">
            {formatDateSinceNow(new Date(history.whenTs))}
          </Box>
        </Box>
        {evText[1].length > 0 && (
          <Box fontSize="sm">{evText[1].join(", ")}</Box>
        )}
      </Flex>
    </Flex>
  );
}
