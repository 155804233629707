import {
  QueryKey,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from "@tanstack/react-query";
import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { z } from "zod";
import type { AppRouter } from "../../../api/application/apibel/funcs/main";
import type { Endpoint } from "../../../api/application/apibel/routing/rpcRouter";
import { apiConfig } from "../constants/config";
import { useAuth } from "./useAuth";

export type GetPaths = AppRouter["getEndpoints"];
export type PostPaths = AppRouter["postEndpoints"];

type InferQueryOptions<TOut> = Omit<
  UseQueryOptions<TOut, Error, TOut, QueryKey>,
  "queryKey" | "queryFn"
>;
type InferMutationOptions<TIn, TOut> = Omit<
  UseMutationOptions<TOut, Error, TIn, unknown>,
  "mutationFn"
>;

const getHeaders = (apiToken: string | null): AxiosRequestConfig["headers"] =>
  apiToken !== null
    ? {
        Authorization: `Bearer ${apiToken}`,
      }
    : {};

export type InferInput<TEndpoint extends Endpoint<any, any>> =
  TEndpoint extends Endpoint<infer Input, any> ? z.input<Input> : undefined;
export type InferOutput<TEndpoint extends Endpoint<any, any>> =
  TEndpoint extends Endpoint<any, infer Output> ? Output : never;

/**
 * Base perform request function. Requires a token.
 * @param method
 * @param pathAndQuery
 * @param body
 * @param apiToken
 * @returns
 */
async function performRequest(
  method: "get" | "post",
  pathAndQuery: string,
  body: any,
  apiToken: string | null,
): Promise<any> {
  const headers = apiToken ? getHeaders(apiToken) : undefined;
  const url = `${apiConfig.apibelBaseUrl}/${pathAndQuery}`;
  const result = await axios.request({ method, data: body, url, headers });
  if (result.data.type === "data") {
    return result.data.data;
  }
  throw Error("Something went wrong");
}

/**
 * Performs a get request to the API. Requires a function that gets the current token, or a token.
 * @param path
 * @param input
 * @returns
 */
export async function get<
  TPath extends keyof GetPaths,
  TEndpoint extends GetPaths[TPath],
>(
  path: TPath,
  input: InferInput<TEndpoint>,
  apiToken: (() => Promise<string | null>) | string | null,
): Promise<InferOutput<TEndpoint>> {
  const encodedInput = encodeURIComponent(JSON.stringify(input));
  const pathAndQuery = `${path}?q=${encodedInput}`;
  const token =
    (typeof apiToken === "function" ? await apiToken() : apiToken) ?? null;
  return performRequest("get", pathAndQuery, undefined, token);
}

/**
 * Performs a post request to the API. Requires a function that gets the current token, or a token
 * @param path
 * @param input
 * @returns
 */
export async function post<
  TPath extends keyof PostPaths,
  TEndpoint extends PostPaths[TPath],
>(
  path: TPath,
  input: InferInput<TEndpoint>,
  apiToken: (() => Promise<string | null>) | string | null,
): Promise<InferOutput<TEndpoint>> {
  const token =
    (typeof apiToken === "function" ? await apiToken() : apiToken) ?? null;
  return performRequest("post", path, input, token);
}

/**
 * Hook that returns a version of the get/post functions that automatically get the api token.
 */
export function useApi() {
  const { getToken } = useAuth();

  async function apiGet<
    TPath extends keyof GetPaths,
    TEndpoint extends GetPaths[TPath],
  >(
    path: TPath,
    input: InferInput<TEndpoint>,
  ): Promise<InferOutput<TEndpoint>> {
    const encodedInput = encodeURIComponent(JSON.stringify(input));
    const pathAndQuery = `${path}?q=${encodedInput}`;
    const token = await getToken();
    return performRequest("get", pathAndQuery, undefined, token);
  }

  async function apiPost<
    TPath extends keyof PostPaths,
    TEndpoint extends PostPaths[TPath],
  >(
    path: TPath,
    input: InferInput<TEndpoint>,
  ): Promise<InferOutput<TEndpoint>> {
    const token = await getToken();
    return performRequest("post", path, input, token);
  }

  return { get: apiGet, post: apiPost };
}

export const buildQueryKey = (path: string, input: any): any[] => [
  ...path.split("/"),
  ":",
  input,
];

export const useApiQuery = <
  TPath extends keyof GetPaths,
  TEndpoint extends GetPaths[TPath],
>(
  path: TPath,
  input: InferInput<TEndpoint>,
  queryOptions: InferQueryOptions<InferOutput<TEndpoint>> = {},
) => {
  const { getToken } = useAuth();
  return useQuery<InferOutput<TEndpoint>, Error>({
    queryKey: buildQueryKey(path, input),
    queryFn: () => get(path, input, getToken),
    ...queryOptions,
  });
};

export const useApiMutation = <
  TPath extends keyof PostPaths,
  TEndpoint extends PostPaths[TPath],
>(
  path: TPath,
  mutationOptions:
    | InferMutationOptions<InferInput<TEndpoint>, InferOutput<TEndpoint>>
    | undefined = undefined,
) => {
  const { getToken } = useAuth();
  return useMutation<
    InferOutput<TEndpoint>,
    Error,
    InferInput<TEndpoint>,
    unknown
  >({
    mutationFn: (input: InferInput<TEndpoint>) => post(path, input, getToken),
    ...mutationOptions,
  });
};

export const isAxiosError = (e: unknown): e is AxiosError =>
  (e as AxiosError).isAxiosError;
