import { useCallback, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import useToast from "./utilities/useToast";

import "./App.css";

import {
  AppState,
  createInitialAppState
} from "./components/App/AppProvider";
import IdleTimer from "./components/App/IdleTimer";
import { ProtectedOuterRoutes } from "./ProtectedOuterRoutes";
import { FailedToLoadScreen } from "./screens/_chakra/FailedToLoadScreen";
import AccountDisabledScreen from "./screens/Home/HomeAccountDisabled";
import HomeLoading from "./screens/Home/HomeLoading";
import { LoginScreen } from "./screens/Login/LoginScreen";
import * as APIBelRequest from "./utilities/apibelRequest";
import { updateLoggingUser } from "./utilities/Logging";
import * as Request from "./utilities/request";
import { useAuth } from "./utilities/useAuth";

export function AuthenticatedApp() {
  const { userID: authedUserID, isLoaded, getToken, signOut } = useAuth();
  const navigate = useNavigate();

  const [baseAppState, setBaseAppState] = useState<AppState>(
    createInitialAppState(),
  );

  const { displayToast } = useToast();

  // Timeout stuff
  const accTimeoutTime =
    (baseAppState.type === "active"
      ? baseAppState.app.accountInfo.accountTimeout
      : null) ?? null;

  const handleSignOut = useCallback(() => {
    signOut()
      .then(() => navigate("/"))
      .catch((err) => console.log(err));
  }, [signOut, navigate]);

  const handleTimeout = useCallback(() => {
    window.localStorage.clear();
    handleSignOut();
  }, [handleSignOut]);

  useEffect(() => {
    if (accTimeoutTime !== null) {
      const timer = new IdleTimer({
        timeout: accTimeoutTime * 60 + 1,
        onTimeout: handleTimeout,
        onExpired: handleTimeout,
      });
      return () => timer.cleanUp();
    }

    return () => {};
  }, [accTimeoutTime, handleTimeout]);

  // Get actual app state once we are logged in
  useEffect(() => {
    const getAppState = async () => {
      try {
        // Get the api token
        const token = await getToken();

        if (!token) {
          throw new Error("No token");
        }

        if (!authedUserID) {
          return;
        }
        const app = await APIBelRequest.get("app/main", null, token);
        const appLEGACY = await Request.get("app", token);

        if (app.account) {
          // Valid account found
          setBaseAppState({
            type: "active",
            auth: {
              clerkUserID: authedUserID,
              apiToken: token,
              isLoggedIn: true,
            },
            currentPageTitle: baseAppState.currentPageTitle,
            app: {
              userAccounts: app.userAccounts,
              accountInfo: app.account,
              userInfo: app.userInfo,
              permissions_LEGACY: appLEGACY.data.Permissions,
              permissions: app.permissions,
              features: {
                enabled: app.features.enabledFeatures,
              },
              attributes: {
                userID: app.userInfo.userID,
                accountID: app.account.accountID,
              },
            },
          });
        } else {
          // No valid account, to limbo we go
          setBaseAppState({
            type: "limbo",
            auth: {
              clerkUserID: authedUserID,
              apiToken: token,
              isLoggedIn: true,
            },
            currentPageTitle: baseAppState.currentPageTitle,
            app: {
              userAccounts: app.userAccounts,
              accountInfo: null,
              userInfo: app.userInfo,
              permissions_LEGACY: {},
              permissions: app.permissions,
              attributes: {
                userID: app.userInfo.userID,
              },
              features: {
                enabled: app.features.enabledFeatures,
              },
            },
          });
        }

        // Update the user were logging for
        updateLoggingUser({
          accountID: app.account?.accountID,
          userID: app.userInfo?.userID,
          authUserID: authedUserID,
          email: app.userInfo?.email,
          username: `${app.userInfo?.firstName} ${app.userInfo?.lastName}`,
        });
      } catch (error) {
        setBaseAppState({
          type: "failed",
          currentPageTitle: baseAppState.currentPageTitle,
          attemptedAuthID: authedUserID,
        });
        displayToast({
          status: "warning",
          title: "Failed to load your account",
          description:
            "Your login session may have expired. Please sign in again, or check your internet connection then refresh the page.",
        });
      }
    };

    // Check initial stuff to see if we should continue
    const isInitial = baseAppState.type === "initial";
    const isFailed = baseAppState.type === "failed";
    const authID =
      baseAppState.type === "initial" || baseAppState.type === "failed"
        ? ""
        : baseAppState.auth.clerkUserID;

    if (isFailed) {
      if (baseAppState.attemptedAuthID === null) {
        return; // Something else failed, we dont want to retry right now
      }
      if (baseAppState.attemptedAuthID === authedUserID) {
        return; // Failed and its the same user, we dont want to retry
      }
    }

    const shouldContinue = isInitial || authID !== authedUserID;
    if (!shouldContinue) {
      return;
    }

    // If clerk is not loaded yet, skip for now
    if (!isLoaded) {
      return;
    }
    if (!authedUserID) {
      return;
    }

    // Set the app state to show we are preparing
    setBaseAppState({
      type: "preparing",
      auth: {
        clerkUserID: authedUserID,
        apiToken: "",
        isLoggedIn: true,
      },
      currentPageTitle: baseAppState.currentPageTitle,
    });

    getAppState();
  }, [navigate, authedUserID, isLoaded, displayToast, baseAppState, getToken]);

  if (baseAppState.type === "failed") {
    return (
      <Routes>
        <Route path="/login/*" element={<LoginScreen />} />
        <Route path="*" element={<FailedToLoadScreen />} />
      </Routes>
    );
  }

  if (
    !isLoaded ||
    baseAppState.type === "initial" ||
    baseAppState.type === "preparing"
  ) {
    return <HomeLoading info="Preparing account..." />;
  }

  if (baseAppState.type === "limbo") {
    return (
      <AccountDisabledScreen userAccounts={baseAppState.app.userAccounts} />
    );
  }

  return (
    <ProtectedOuterRoutes
      baseAppState={baseAppState}
      setBaseAppState={setBaseAppState}
    />
  );
}
