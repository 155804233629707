import { Button, Text, VStack } from "@chakra-ui/react";
import { AdminPortalUserAuthDetails } from "../../../../shared/v2/definitions/admin.types";
import { ServiceDataRow } from "../ServiceDataRow";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";

type Props = {
  isFetching: boolean;
  userAuth: AdminPortalUserAuthDetails;
  onClickClearMfa: () => void;
  onClickResetPassword: () => void;
};

export function ServiceUserSecurityCard({
  isFetching,
  userAuth,
  onClickClearMfa,
  onClickResetPassword,
}: Props) {
  return (
    <ServiceDataSectionCard
      title="Security"
      actions={
        <>
          <Button
            size="sm"
            isDisabled={isFetching}
            onClick={onClickResetPassword}>
            Reset password
          </Button>
          <Button
            onClick={onClickClearMfa}
            size="sm"
            isDisabled={isFetching || !userAuth.hasMfa}>
            Clear MFA
          </Button>
        </>
      }>
      <VStack w="100%" spacing="2" align="start">
        <ServiceDataRow>
          <span>Password: </span>
          {userAuth.hasPassword ? (
            <Text color="gray.600" as="span">
              ●●●●●●●●●●
            </Text>
          ) : (
            <span>No password set</span>
          )}
        </ServiceDataRow>
        <ServiceDataRow>
          <span>Multifactor: </span>
          {userAuth.hasMfa ? (
            <Text fontWeight="bold" color="gray.600" as="span">
              MFA setup and active
            </Text>
          ) : (
            <span>No MFA setup</span>
          )}
        </ServiceDataRow>
      </VStack>
    </ServiceDataSectionCard>
  );
}
