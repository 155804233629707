import { HStack, Text } from "@chakra-ui/react";
import { formatUtcDateReadableShort } from "../../../utilities/dateUtils";

type Props = {
  children?: React.ReactNode;
  date?: string | Date;
};
export function ServiceDataRow({ children, date }: Props) {
  return (
    <HStack w="100%" align="center" justify="space-between">
      <HStack fontSize="sm" align="center" justify="start" spacing="1">
        {children}
      </HStack>
      {date && (
        <Text fontSize="sm" color="gray.700">
          {formatUtcDateReadableShort(new Date(date))}
        </Text>
      )}
    </HStack>
  );
}
