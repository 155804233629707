import { z } from "zod";

export const BlueprintFileValidator = z.object({
  type: z.literal("certcrowd-blueprint"),
  version: z.number(),
  src: z.object({
    name: z.string(),
    ID: z.string(),
  }),
  object: z.literal("manual"),
  data: z.array(z.unknown())
});

/**
 * Blueprint exports are json files in the format:
 * {
 *  type: "certcrowd-blueprint",
 *  version: 1,
 *  src: { name, ID },
 *  object: "manual",
 *  data: [ ... ]
 * }
 * @param data
 * @param filename
 */
export function downloadBlueprintExport(
  data: any[],
  params: {
    accountName: string,
    accountID: string,
    blueprintType: string,
    exportName: string,
  }
) {
  const { accountName, accountID, blueprintType, exportName } = params;
  const jsonObj = {
    type: "certcrowd-blueprint",
    version: 1,
    src: {
      name: accountName,
      ID: accountID,
    },
    object: blueprintType,
    data,
  };

  const currDate = new Date();
  const currDateStr = currDate.toISOString().split("T")[0]?.replace(/-/g, "");

  const filename = `${currDateStr}--${accountID.slice(-6)}-${blueprintType}-${exportName}.ccbp`;

  const content = JSON.stringify(jsonObj);

  const linkElement = document.createElement("a");
  linkElement.href = `data:text/plain;charset=utf-8,${encodeURIComponent(
    content,
  )}`;
  linkElement.download = filename;
  linkElement.style.display = "none";
  document.body.appendChild(linkElement);
  linkElement.click();
  document.body.removeChild(linkElement);
}
