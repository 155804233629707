import React from "react";
import { Box, HStack, Image, Heading, Button, Icon } from "@chakra-ui/react";
import { Routes, Route, Link } from "react-router-dom";
import { useAppState } from "../../components/App/AppProvider";
import * as Permissions from "../../shared/constants/permission";
import * as Access from "../../utilities/access";
import NotFound from "../NotFound";

import AdminScreen from "./AdminScreen";
import CreateAccountScreen from "./CreateAccount";
import CreateNotification from "./CreateNotification";
import SuspendAccountScreen from "./SuspendAccount";
import { RiDoorOpenLine } from "react-icons/ri";

const AdminRoutes = () => {
  const { app } = useAppState();

  // Do the access check here, preventing access to any of the sub routes
  return Access.checkAccess(
    app.permissions_LEGACY,
    Permissions.CodeAdminAccounts,
    Permissions.TypeRead,
    false,
  ) ? (
    <>
      <Box
        borderBottomWidth="1px"
        borderBottomStyle="solid"
        borderBottomColor="brand.50"
        boxShadow="sm"
        zIndex={12}
        width="full"
        pos="fixed"
        h="top-bar"
        bg="brand.800"
        px="4">
        <HStack h="full" justify="space-between" align="center">
          <HStack h="full" align="center" spacing="4">
            <Heading color="whiteAlpha.900" size="md">
              Legacy Admin Portal
            </Heading>
          </HStack>
          <Button
            size="sm"
            leftIcon={<Icon as={RiDoorOpenLine} />}
            as={Link}
            to="/">
            Back to CertCrowd
          </Button>
        </HStack>
      </Box>
      <Box minH="100vh" w="100%" pt="top-bar">
        <Routes>
          <Route index element={<AdminScreen />} />
          <Route path="create" element={<CreateAccountScreen />} />
          <Route path="suspend" element={<SuspendAccountScreen />} />
          <Route path="notify" element={<CreateNotification />} />
        </Routes>
      </Box>
    </>
  ) : (
    <>
      <NotFound />
    </>
  );
};

export default AdminRoutes;
