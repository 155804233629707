import { Button, Divider, Text, VStack } from "@chakra-ui/react";
import { useRef, useState } from "react";
import {
  AdminPortalAccount,
  AdminPortalUser,
} from "../../../../shared/v2/definitions/admin.types";
import { api } from "../../../../utilities/apiRequest";
import { BlueprintFileValidator } from "../../../../utilities/blueprintExport";
import useToast from "../../../../utilities/useToast";
import { ServiceAccountExportManual } from "../ServiceAccountExportManual";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";

type Props = {
  isFetching: boolean;
  account: AdminPortalAccount;
  users: AdminPortalUser[];
};

export function ServiceAccountImportExportCard({
  isFetching,
  account,
  users,
}: Props) {
  const [isExporting, setIsExporting] = useState(false);
  const [responsibleUserID, setResponsibleUserID] = useState<string | null>(
    null,
  );
  const [doMerge, setDoMerge] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const importManualsMutation = api.admin.blueprint.importManuals.useMutation();

  const { displayToast } = useToast();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClickImportManuals = async () => {
    if (isImporting) return;
    if (!fileInputRef.current?.files) {
      return;
    }

    if (!responsibleUserID) {
      setError("Please select a responsible user");
      return;
    }

    const file = fileInputRef.current.files[0];
    if (!file) {
      return;
    }
    // Parse the contents of the file as json
    const fileContents = await file.text();
    const fileData = JSON.parse(fileContents);

    const parsed = BlueprintFileValidator.safeParse(fileData);

    if (!parsed.success || parsed.data.object !== "manual") {
      setError("Invalid blueprint file");
      return;
    }

    const manuals = parsed.data.data;

    if (manuals.length <= 0) {
      setError("No manuals in blueprint");
      return;
    }

    setError(null);
    fileInputRef.current.value = "";

    setIsImporting(true);

    // Call the mutation to import the manuals
    try {
      await importManualsMutation.mutateAsync({
        accountID: account.accountID,
        manuals: manuals as any[],
        actingUser: "system",
        responsibleUserID,
        merge: doMerge
          ? {
              map: [["{CompanyName}", account.accountName]],
              mergeInTitle: true,
            }
          : undefined,
      });
      displayToast({
        title: "Imported manuals",
        status: "success",
      });
      setTimeout(() => {
        setIsImporting(false);
      }, 1000);
    } catch (e) {
      setError((e as Error).message);
      setIsImporting(false);
    }
  };

  return (
    <ServiceDataSectionCard
      title="Import/Export data"
      actions={
        null
        // account.accountStatus !== "deleted" && (
        //   <Button
        //     onClick={onClickSetLicenseAmount}
        //     isDisabled={isFetching}
        //     size="sm">
        //     Edit license
        //   </Button>
        // )
      }>
      <VStack w="100%" spacing="2" align="start">
        {isExporting ? (
          <ServiceAccountExportManual
            accountID={account.accountID}
            accountName={account.accountName}
          />
        ) : (
          <>
            <VStack spacing="2" align="start">
              <Text>Import Manual:</Text>
              {error && <Text color="red.700">{error}</Text>}
              <input ref={fileInputRef} type="file" />
              <label htmlFor="responsible-user">
                Responsible User
                <select
                  name="responsible-user"
                  id="responsible-user"
                  value={responsibleUserID || ""}
                  onChange={(e) => setResponsibleUserID(e.target.value)}>
                  <option value="NONE">Select a user</option>
                  {users.map((user) => (
                    <option key={user.userID} value={user.userID}>
                      {user.firstName} {user.lastName} - {user.primaryEmail}
                    </option>
                  ))}
                </select>
              </label>
              <label htmlFor="merge">
                Merge
                <input
                  type="checkbox"
                  id="merge"
                  checked={doMerge}
                  onChange={(e) => setDoMerge(e.target.checked)}
                />
              </label>
              <Button
                onClick={handleClickImportManuals}
                size="sm"
                isDisabled={
                  responsibleUserID === null ||
                  responsibleUserID === "NONE" ||
                  !fileInputRef.current?.files
                }
                isLoading={isImporting}>
                Perform import
              </Button>
            </VStack>
            <Divider />
            <Button
              size="sm"
              isDisabled={isFetching}
              onClick={() => setIsExporting(true)}>
              Export manuals
            </Button>
          </>
        )}
      </VStack>
    </ServiceDataSectionCard>
  );
}
