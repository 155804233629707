import { Box, Button, HStack, Heading, Icon, Image } from "@chakra-ui/react";
import { RiDoorOpenLine } from "react-icons/ri";
import { Link, Route, Routes } from "react-router-dom";
import FullLoader from "../../components/chakra/FullLoader";
import headerImg from "../../images/logo_landscape.png";
import { api } from "../../utilities/apiRequest";
import NotFoundScreen from "../NotFound";
import ServiceAccountScreen from "./ServiceAccountScreen";
import ServiceAccountsScreen from "./ServiceManageAccountsScreen";
import ServiceManageUsersScreen from "./ServiceManageUsersScreen";
import ServicePortalHomeScreen from "./ServicePortalHomeScreen";
import ServiceUserScreen from "./ServiceUserScreen";

export default function ServicePortalRoutes() {
  const permissionsQuery = api.admin.getOwnPermissions.useQuery();

  if (permissionsQuery.isLoading) {
    return (
      <Box pt="12%">
        <FullLoader />
      </Box>
    );
  }

  if (
    permissionsQuery.isSuccess &&
    permissionsQuery.data.servicePermissions.access
  ) {
    return (
      <chakra-scope>
        <Box minW="100vw" minH="100vh">
          <Box
            borderBottomWidth="1px"
            borderBottomStyle="solid"
            borderBottomColor="brand.50"
            boxShadow="sm"
            zIndex={12}
            width="full"
            pos="fixed"
            h="top-bar"
            bg="brand.800"
            px="4">
            <HStack h="full" justify="space-between" align="center">
              <HStack h="full" align="center" spacing="4">
                <Image src={headerImg} h="1.25rem" maxH="50%" />
                <Heading color="whiteAlpha.900" size="md">
                  Admin Service Portal
                </Heading>
              </HStack>
              <Button
                size="sm"
                leftIcon={<Icon as={RiDoorOpenLine} />}
                as={Link}
                to="/">
                Back to CertCrowd
              </Button>
            </HStack>
          </Box>
          <Box minH="100vh" w="100%" pt="top-bar">
            <Routes>
              <Route
                path="/"
                element={
                  <ServicePortalHomeScreen
                    servicePermissions={
                      permissionsQuery.data.servicePermissions
                    }
                  />
                }
              />
              <Route
                path="/users"
                element={
                  <ServiceManageUsersScreen
                    servicePermissions={
                      permissionsQuery.data.servicePermissions
                    }
                  />
                }
              />
              <Route
                path="/users/:userID"
                element={
                  <ServiceUserScreen
                    servicePermissions={
                      permissionsQuery.data.servicePermissions
                    }
                  />
                }
              />
              <Route
                path="/accounts"
                element={
                  <ServiceAccountsScreen
                    servicePermissions={
                      permissionsQuery.data.servicePermissions
                    }
                  />
                }
              />

              <Route
                path="/accounts/:accountID"
                element={
                  <ServiceAccountScreen
                    servicePermissions={
                      permissionsQuery.data.servicePermissions
                    }
                  />
                }
              />
            </Routes>
          </Box>
        </Box>
      </chakra-scope>
    );
  }

  return <NotFoundScreen />;
}
