import { As, Box, Button, Icon, Text, VStack } from "@chakra-ui/react";
import { RiArrowRightSFill } from "react-icons/ri";
import { Link } from "react-router-dom";

type Props = {
  children: React.ReactNode;
  icon: As;
  link?: string;
  onClick?: () => void;
};

export default function ServicePortalButton({
  children,
  link,
  icon,
  onClick,
}: Props) {
  return (
    <Box
      w="100%"
      h="100%"
      minH="12rem"
      _hover={{
        ".icon": {
          transform: "translateX(6px)",
          opacity: 1.0,
        },
      }}>
      <Button
        px="8"
        py="6"
        w="100%"
        h="100%"
        variant="outline"
        as={link !== undefined ? Link : undefined}
        to={link || ""}
        onClick={onClick}>
        <Icon
          position="absolute"
          top="0"
          right="1"
          opacity="0"
          transition="transform, opacity 0.5s"
          className="icon"
          color="brand.500"
          boxSize="1.5rem"
          as={RiArrowRightSFill}
        />
        <VStack>
          <Box>
            <Icon boxSize="2rem" as={icon} />
          </Box>
          <Text fontSize="lg">{children}</Text>
        </VStack>
      </Button>
    </Box>
  );
}
