import { Badge, Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { AdminPortalAccount } from "../../../../shared/v2/definitions/admin.types";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";
import { TagColorSchemMap } from "../../ServiceManageAccountsScreen";

type Props = {
  isFetching: boolean;
  account: AdminPortalAccount;
  onClickSetTag: (tag: "blueprint" | "demo") => void;
};

export function ServiceAccountTagsCard({
  isFetching,
  account,
  onClickSetTag,
}: Props) {
  return (
    <ServiceDataSectionCard
      title="Account tags"
      actions={
        account.accountStatus !== "deleted" && (
          <>
            {/* <Button
              onClick={() => onClickSetTag("blueprint")}
              isDisabled={isFetching}
              size="sm">
              {account.tags.includes("blueprint")
                ? "Remove blueprint"
                : "Add blueprint"}
            </Button>
            <Button
              onClick={() => onClickSetTag("demo")}
              isDisabled={isFetching}
              size="sm">
              {account.tags.includes("demo") ? "Remove demo" : "Add demo"}
            </Button> */}
          </>
        )
      }>
      <VStack w="100%" spacing="2" align="start">
        <HStack>
          {account.tags.map((tag) => (
            <Badge colorScheme={TagColorSchemMap[tag]} key={tag}>
              {tag}
            </Badge>
          ))}
          {account.tags.length === 0 && (
            <Text fontStyle="italic" color="gray.700">
              No tags
            </Text>
          )}
        </HStack>
      </VStack>
    </ServiceDataSectionCard>
  );
}
