import {
  Button,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ModalBody } from "react-bootstrap";
import * as Feather from "react-feather";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import * as V1Request from "../../../utilities/request";
import Icon from "../../UI/Icon";
import { useAuth } from "../../../utilities/useAuth";

type OnDemandAction = {
  RequirementOnDemandActionID: string | null;
  RequirementName: string;
  ActionName: string;
  ActionObjectTypeIcon: keyof typeof Feather;
};

type Props = {
  onClose: () => void;
};

const StartATaskModal = ({ onClose }: Props) => {
  const navigate = useNavigate();
  const { getToken } = useAuth();
  const onDemandActions = useQuery({
    queryKey: ["v1", "ondemandactions"],
    queryFn: () => V1Request.get("app/ondemandaction", getToken),
  });

  return (
    <chakra-scope>
      <ModalContent>
        <ModalHeader>Start a Task</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={2} align="start" w="full">
            {onDemandActions.isFetching ? (
              <>
                <Skeleton width="full">
                  <Button variant="link" />
                </Skeleton>
                <Skeleton width="full">
                  <Button variant="link" />
                </Skeleton>
                <Skeleton width="full">
                  <Button variant="link" />
                </Skeleton>
              </>
            ) : onDemandActions.isSuccess ? (
              <>
                {onDemandActions.data.data.OnDemandActions.length >= 1 ? (
                  onDemandActions.data.data.OnDemandActions.map(
                    (action: OnDemandAction) => (
                      <Button
                        leftIcon={<Icon name={action.ActionObjectTypeIcon} />}
                        variant="link"
                        onClick={() => {
                          onClose();
                          navigate(
                            `/ondemandaction/${action.RequirementOnDemandActionID}`,
                          );
                        }}>
                        {action.ActionName}
                      </Button>
                    ),
                  )
                ) : (
                  <Text>No on demand tasks available</Text>
                )}
              </>
            ) : (
              <p>Something went wrong</p>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </chakra-scope>
  );
};

export default StartATaskModal;
