import { VStack } from "@chakra-ui/react";
import {
  AdminPortalUserAuthDetails
} from "../../../../shared/v2/definitions/admin.types";
import { ServiceDataNoContent } from "../ServiceDataNoContent";
import { ServiceDataRow } from "../ServiceDataRow";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";

type Props = {
  isFetching: boolean;
  userAuth: AdminPortalUserAuthDetails;
};

export function ServiceUserSSOCard({ isFetching, userAuth }: Props) {
  return (
    <ServiceDataSectionCard title="SSO">
      <VStack w="100%" spacing="2" align="start">
        {userAuth.ssoAccounts.length >= 1 ? (
          userAuth.ssoAccounts.map((ssoAcc) => (
            <ServiceDataRow key={ssoAcc.socialAccountID}>
              <span>
                {ssoAcc.provider} - {ssoAcc.email}
              </span>
            </ServiceDataRow>
          ))
        ) : (
          <ServiceDataNoContent />
        )}
      </VStack>
    </ServiceDataSectionCard>
  );
}
