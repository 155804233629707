import { z } from "zod";

export const config = z
  .object({
    // Endpoints
    VITE_AWS_SERVICE_ENDPOINT: z.string(),
    VITE_AWS_APIBEL_SERVICE_ENDPOINT: z.string(),
    VITE_AWS_APIBEL_SERVICE_ENDPOINT_V3: z.string(),
    // Cognito
    VITE_COGNITO_USER_POOL_REGION: z.string(),
    VITE_COGNITO_USER_POOL_ID: z.string(),
    VITE_COGNITO_USER_POOL_CLIENT_ID: z.string(),
    // Keys
    VITE_FROALA_API_KEY: z.string(),
    // Misc
    VITE_VERSION: z.string(),
    VITE_CLERK_PUBLISHABLE_KEY: z.string(),
  })
  .parse(import.meta.env);

export const apiConfig = {
  controllerBaseUrl: config.VITE_AWS_SERVICE_ENDPOINT,
  apibelBaseUrl: config.VITE_AWS_APIBEL_SERVICE_ENDPOINT,
  apibelBaseUrlV3: config.VITE_AWS_APIBEL_SERVICE_ENDPOINT_V3,
};
