import { Box, Button, SimpleGrid, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import ChakraScreenContainer from "../../../components/chakra/ChakraScreenContainer";
import FullLoader from "../../../components/chakra/FullLoader";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import { ManualCard } from "./components/ManualCard";
import { useAppState } from "../../../components/App/AppProvider";

export default function ManualsScreen() {
  const { app } = useAppState();
  const manuals = useApiQuery("manual/many", {});
  const manualCreateMutation = useApiMutation("manual/create");

  const handleCreateManual = async () => {
    await manualCreateMutation.mutateAsync({
      manual: {
        name: "System Manual",
      },
    });
    manuals.refetch();
  };

  const hasAnyManuals = manuals.isSuccess && manuals.data.length > 0;

  const hasCreateManualPermission = app.permissions.MANUAL.CREATE.ALL;

  const canCreateManual =
    manuals.isSuccess && manuals.data.length < 3 && hasCreateManualPermission;

  const canEditRoles =
    app.permissions.USER_SETTINGS.UPDATE.ALL ||
    app.permissions.ACCOUNT_SETTINGS;

  const isLoading = manuals.isFetching || manualCreateMutation.isPending;

  return (
    <chakra-scope>
      <ChakraScreenContainer
        contentSize="container.lg"
        pageTitle="Manuals"
        headerEndContent={
          hasAnyManuals && canCreateManual ? (
            <Button size="sm" onClick={handleCreateManual}>
              Create Manual
            </Button>
          ) : null
        }>
        {isLoading ? (
          <Box pt="8vh">
            <FullLoader />
          </Box>
        ) : manuals.isSuccess && manuals.data.length <= 0 ? (
          <Box
            mt="6"
            borderStyle="dashed"
            borderWidth="1px"
            borderColor="gray.200"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            textAlign="center"
            height="200px">
            <Text maxWidth="sm">
              You don't have any manuals yet.
              {canCreateManual && " Create a manual to get started."}
            </Text>
            {canEditRoles && !hasCreateManualPermission && (
              <Box pt="4">
                <Text>You dont have permission to create a new manual.</Text>
                <Text>
                  Edit role permissions in settings to give access to the
                  manuals module.
                </Text>
                <Button variant="link" as={Link} to="/settings/roles" mt="4">
                  Go to roles & permissions
                </Button>
              </Box>
            )}
            {canCreateManual && (
              <Button onClick={handleCreateManual} mt="4">
                Create Manual
              </Button>
            )}
          </Box>
        ) : (
          <SimpleGrid pt="4" flexWrap="wrap" columns={{base: 1, md: 3}} spacing="6">
            {manuals.data?.map((manual) => <ManualCard manual={manual} />)}
          </SimpleGrid>
        )}
      </ChakraScreenContainer>
    </chakra-scope>
  );
}
