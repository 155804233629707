import { SimpleGrid } from "@chakra-ui/react";
import { RiFolderUserFill, RiShieldUserFill } from "react-icons/ri";
import ChakraScreenContainer from "../../components/chakra/ChakraScreenContainer";
import NotFoundScreen from "../NotFound";
import ServicePortalButton from "./ServicePortalButton";
import { UserServicePermissions } from "../../shared/v2/constants/ServicePermissions";

type Props = {
  servicePermissions: UserServicePermissions;
};

export default function ServicePortalHomeScreen({ servicePermissions }: Props) {
  if (!servicePermissions.access) {
    return <NotFoundScreen />;
  }

  return (
    <ChakraScreenContainer>
      <SimpleGrid w="100%" columns={{ base: 3, md: 4, lg: 5 }} gap="4" pt="4">
        <ServicePortalButton
          icon={RiFolderUserFill}
          link="/_ccserviceportal/users">
          Manage Users
        </ServicePortalButton>
        <ServicePortalButton
          icon={RiShieldUserFill}
          link="/_ccserviceportal/accounts">
          Manage Accounts
        </ServicePortalButton>
      </SimpleGrid>
    </ChakraScreenContainer>
  );
}
