import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { NuqsAdapter } from "nuqs/adapters/react-router/v6";
import React, { useCallback } from "react";
import { BrowserRouter as Router, useNavigate } from "react-router-dom";

// Mantine styles
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";

import "./App.css";
import "./manualTiptap.css";

import { ClerkProvider, SignedIn, SignedOut } from "@clerk/clerk-react";
import { AuthenticatedApp } from "./AuthenticatedApp";
import { config } from "./constants/config";
import CSSReset from "./screens/ChakraCSSReset";
import { ErrorBoundary } from "./screens/ErrorBoundary/ErrorBoundary";
import chakraTheme, { clerkStyle } from "./theme";
import { UnauthenticatedApp } from "./UnauthenticatedApp";
import useToast from "./utilities/useToast";
import { api, useTrpcClient } from "./utilities/apiRequest";
import { httpBatchLink } from "@trpc/client";
import { useAuth } from "./utilities/useAuth";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000,
      gcTime: 1000 * 10,
      retry: 0, // Disable retries - temp
      refetchOnWindowFocus: false,
    },
  },
});

// anything that exists over each page goes here
// managed auth, and rendering the correct routes
const App = () => {
  const { getToken } = useAuth();
  const trpcClient = useTrpcClient(getToken);

  if (!trpcClient) {
    return null;
  }

  // Our main routes either UnauthenticatedApp or AuthenticatedApp depending on if the user is logged in
  // UnauthenticatedApp just redirects to the login route
  return (
    <QueryClientProvider client={queryClient}>
      <api.Provider client={trpcClient} queryClient={queryClient}>
        <SignedOut>
          <UnauthenticatedApp />
        </SignedOut>
        <SignedIn>
          <AuthenticatedApp />
        </SignedIn>
      </api.Provider>
    </QueryClientProvider>
  );
};

// Wrapper to add ClerkProvider to the app
const AppWithAuth = () => {
  const navigate = useNavigate();
  return (
    <ClerkProvider
      appearance={clerkStyle}
      publishableKey={config.VITE_CLERK_PUBLISHABLE_KEY}
      afterSignOutUrl="/"
      routerPush={(to) => navigate(to)}
      routerReplace={(to) => navigate(to, { replace: true })}>
      <App />
    </ClerkProvider>
  );
};

// Wrapper to add router and other providers to the app
const AppWithRouter: React.FC = () => {
  return (
    <ChakraProvider theme={chakraTheme} resetCSS={false}>
      <CSSReset />
      <NuqsAdapter>
        <Router>
          <ErrorBoundary>
            <AppWithAuth />
          </ErrorBoundary>
        </Router>
      </NuqsAdapter>
    </ChakraProvider>
  );
};

export default AppWithRouter;
