import moment from "moment";
import * as Yup from "yup";
import {
  CustomFieldIDToName,
  CustomFieldTypes,
} from "../../constants/customFieldType";
import { CustomField } from "./types";

const DATE_PARSE_FORMAT = "D/MM/YY";

const momentDateSchema = () =>
  Yup.date().transform((_, originalValue) => {
    const value = moment(originalValue, DATE_PARSE_FORMAT);
    return value.isValid() ? value.toDate() : new Date("");
  });

const baseValidationSchema = Yup.object({
  Name: Yup.string().required("Name is required").max(500, "Name too long, max 500 characters"),
  Description: Yup.string().max(5000, "Description too long, max 5000 characters"),
});

const createRegisterSchema = (customFields: CustomField[]): Yup.ArraySchema<object> => {
  console.log("Creating validation schema for custom fields");
  let customFieldSetSchema = Yup.object();

  customFields.forEach((customField) => {
    let customFieldItemSchema: Yup.MixedSchema;
    // Check type of custom field
    switch (customField.CustomFieldTypeID) {
      case CustomFieldTypes.List:
        // List schema is just one of the possible values
        customFieldItemSchema = Yup.string().oneOf(
          customField.ListValues.map(
            // Map on items not ids
            (listItem) => listItem.ListValue,
          ),
        );
        break;
      case CustomFieldTypes.MultiList:
        customFieldItemSchema = Yup.string().test(
          "contains correct values",
          "contains correct values",
          (val) => {
            if (typeof val === "string") {
              // Empty strings are just empty arrays which is fine.
              if (val === "") return true;
              const values = val.split(",").map((item) => item.trim());
              const listValues: string[] = customField.ListValues.map(
                (listItem) => listItem.ListValue,
              );
              for (const value of values) {
                if (!listValues.includes(value)) {
                  return false;
                }
              }
              return true;
            }
            return false;
          },
        );
        break;
      case CustomFieldTypes.TextType:
      case CustomFieldTypes.LongTextType:
        customFieldItemSchema = Yup.string().max(5000);
        break;
      case CustomFieldTypes.NumberType:
        customFieldItemSchema = Yup.number();
        break;
      case CustomFieldTypes.DateType:
        customFieldItemSchema = momentDateSchema();
        break;
      default:
        throw Error(
          `Unsupported custom field type: ${
            CustomFieldIDToName[customField.CustomFieldTypeID]
          }`,
        );
    }
    // Make required if field is required
    if (customField.IsMandatory) {
      customFieldItemSchema = customFieldItemSchema.required();
    }
    customFieldSetSchema = customFieldSetSchema.shape({
      [customField.Label]: customFieldItemSchema,
    });
  });

  const finalSchema = Yup.array(
    customFieldSetSchema.concat(baseValidationSchema),
  );
  console.log("Created schema: ", finalSchema);
  return finalSchema;
};
export default createRegisterSchema;
