import { Badge, Icon, Tooltip, VStack } from "@chakra-ui/react";
import { RiErrorWarningLine, RiShieldCheckLine } from "react-icons/ri";
import { ServiceDataRow } from "../ServiceDataRow";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";
import {
  AdminPortalUserAuthDetails,
  AdminPortalUser,
} from "../../../../shared/v2/definitions/admin.types";

type Props = {
  isFetching: boolean;
  user: AdminPortalUser & { auth: AdminPortalUserAuthDetails };
};

export function ServiceUserEmailsCard({ isFetching, user }: Props) {
  const emails: {
    emailID: string;
    emailAddress: string;
    isVerified: boolean;
  }[] = user.auth.emails
    ? user.auth.emails
    : [
        {
          emailID: "unknown",
          emailAddress: user.primaryEmail,
          isVerified: true,
        },
      ];
  return (
    <ServiceDataSectionCard title="Email addresses">
      <VStack w="100%" spacing="2" align="start">
        {emails.map((email) => (
          <ServiceDataRow key={email.emailID}>
            <span>{email.emailAddress}</span>
            <Tooltip label={email.isVerified ? "Verified" : "Not verified"}>
              <span>
                <Icon
                  color={email.isVerified ? "brand.600" : "red.600"}
                  ml="1"
                  as={email.isVerified ? RiShieldCheckLine : RiErrorWarningLine}
                />
              </span>
            </Tooltip>
            {email.emailAddress === user.primaryEmail && (
              <Badge ml="2" size="sm">
                Primary
              </Badge>
            )}
          </ServiceDataRow>
        ))}
      </VStack>
    </ServiceDataSectionCard>
  );
}
