import {
  useAuth as useAuthClerk,
  useUser,
  useUser as useUserClerk,
} from "@clerk/clerk-react";
import { useState } from "react";
import {
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "@tanstack/react-query";

/**
 * All front end stuff that touches clerk should be here, isntead of importing clerk directly
 */

export type AuthState = {
  userID: string | null;
  isLoaded: boolean;
  getToken: () => Promise<string | null>;
  signOut: ReturnType<typeof useAuthClerk>["signOut"];
};

/**
 * Returns the api token for the current authenticated user
 * This should trigger a refresh of the api token if needed
 */
export function useAuth(): AuthState {
  const { getToken, userId, isLoaded, signOut } = useAuthClerk();
  return { getToken, isLoaded, userID: userId || null, signOut };
}

/**
 *
 */
export function useAuthUser() {
  const { user } = useUserClerk();
  return user;
}

type AuthSession = {
  sessionID: string;
  isCurrent: boolean;
  lastActiveDate: Date;
  revokeSession: () => Promise<void>;
  host: {
    browserName?: string;
    browserVersion?: string;
    deviceType?: string;
    location: string;
    isMobile: boolean;
  };
};

type AuthEmail = {
  emailAddress: string;
  id: string;
  isVerified: boolean;
};

export type AlternateAuthEmail = AuthEmail & {
  setPrimary: () => Promise<void>;
  delete: () => Promise<void>;
  sendVerificationLink: () => Promise<void>;
};

type AuthEmails = {
  primaryEmail: AuthEmail | null;
  alternateEmails: AlternateAuthEmail[];
  createEmail: (email: string) => Promise<string>;
  isPending: boolean;
};

export function useAuthEmails(): AuthEmails | null {
  const [isPending, setIsPending] = useState(false);
  const { user } = useUserClerk();
  if (!user) return null;
  const primary = user.primaryEmailAddress;
  const alternate = user.emailAddresses
    .filter((email) => email.id !== primary?.id)
    .sort((a, b) => {
      const aVer = a.verification.status === "verified" ? 1 : 0;
      const bVer = b.verification.status === "verified" ? 1 : 0;
      if (aVer !== bVer) return bVer - aVer;
      return a.emailAddress.localeCompare(b.emailAddress);
    });
  return {
    primaryEmail: primary
      ? {
          emailAddress: primary.emailAddress,
          id: primary.id,
          isVerified: primary.verification.status === "verified",
        }
      : null,
    alternateEmails: alternate.map((email) => ({
      emailAddress: email.emailAddress,
      id: email.id,
      isVerified: email.verification.status === "verified",
      setPrimary: async () => {
        setIsPending(true);
        await user.update({
          primaryEmailAddressId: email.id,
        });
        setIsPending(false);
      },
      delete: async () => {
        setIsPending(true);
        await email.destroy();
        setIsPending(false);
      },
      sendVerificationLink: async () => {
        await email.prepareVerification({
          strategy: "email_link",
          redirectUrl: `${window.location.origin}/settings/profile`,
        });
      },
    })),
    createEmail: async (email: string) => {
      setIsPending(true);
      const res = await user.createEmailAddress({
        email,
      });
      await res.prepareVerification({
        strategy: "email_link",
        redirectUrl: `${window.location.origin}/settings/profile`,
      });
      setIsPending(false);
      return res.id;
    },
    isPending,
  };
}

type AuthSessions = {
  sessions: AuthSession[];
};

/**
 *
 */
export function useAuthSessionsQuery(): UseQueryResult<AuthSessions, Error> {
  const { user } = useUserClerk();
  const queryClient = useQueryClient();
  const auth = useAuthClerk();
  return useQuery<AuthSessions, Error>({
    queryKey: ["clerk:auth", "useAuthSessions"],
    queryFn: async () => {
      const sessionsData = await user?.getSessions();
      const sessions: AuthSession[] =
        sessionsData
          ?.filter((sess) => sess.status === "active")
          .map((sess) => {
            return {
              sessionID: sess.id,
              lastActiveDate: sess.lastActiveAt,
              isCurrent: sess.id === auth.sessionId,
              revokeSession: async () => {
                await sess.revoke();
                queryClient.invalidateQueries([
                  "clerk:auth",
                  "useAuthSessions",
                ]);
              },
              host: {
                browserName: sess.latestActivity.browserName,
                browserVersion: sess.latestActivity.browserVersion,
                deviceType: sess.latestActivity.deviceType,
                location: `${sess.latestActivity.city}, ${sess.latestActivity.country}`,
                isMobile: !!sess.latestActivity.isMobile,
              },
            } satisfies AuthSession;
          }) || [];
      sessions.sort((a, b) => {
        return a.isCurrent ? -1 : b.isCurrent ? 1 : 0;
      });
      return {
        sessions,
      };
    },
  });
}

export function useAuthTOTP() {
  const { user } = useUser();
  if (!user) return null;
  const { verifyTOTP, createTOTP, totpEnabled, disableTOTP } = user;
  return {
    verifyTOTP,
    createTOTP,
    totpEnabled,
    disableTOTP,
  };
}

export function useAuthPasswords() {
  const { user } = useUser();
  if (!user) return null;
  const { passwordEnabled, updatePassword, removePassword } = user;
  return {
    passwordEnabled,
    updatePassword,
    removePassword,
  };
}
