import { Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { AdminPortalUser } from "../../../../shared/v2/definitions/admin.types";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";

type Props = {
  isFetching: boolean;
  user: AdminPortalUser;
  onClickSetAsInternal: () => void;
};

export function ServiceUserDetailsCard({ isFetching, user, onClickSetAsInternal }: Props) {
  return (
    <ServiceDataSectionCard
      title="User details"
      actions={<Button onClick={onClickSetAsInternal} isLoading={isFetching} isDisabled={isFetching} size="sm">{user.isInternal ? "Set as not internal" : "Set as internal"}</Button>}>
      <VStack w="100%" spacing="2" align="start">
        <Text>
          {user.firstName} {user.lastName}
        </Text>
        <Divider />
        <HStack>
          <Text fontWeight="400">Is internal/staff?</Text>
          <Text fontWeight="600" color="brand.800">
            {user.isInternal ? "True" : "False"}
          </Text>
        </HStack>
      </VStack>
    </ServiceDataSectionCard>
  );
}
