import { Badge, Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { AdminPortalAccount } from "../../../../shared/v2/definitions/admin.types";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";
import { TagColorSchemMap } from "../../ServiceManageAccountsScreen";

type Props = {
  isFetching: boolean;
  account: AdminPortalAccount;
  onClickSetLicenseAmount: () => void;
};

export function ServiceAccountLicenseCard({
  isFetching,
  account,
  onClickSetLicenseAmount,
}: Props) {
  return (
    <ServiceDataSectionCard
      title="License"
      actions={null
        // account.accountStatus !== "deleted" && (
        //   <Button
        //     onClick={onClickSetLicenseAmount}
        //     isDisabled={isFetching}
        //     size="sm">
        //     Edit license
        //   </Button>
        // )
      }>
      <VStack w="100%" spacing="2" align="start">
        <Badge
          colorScheme={account.license.licenseStatus === "ok" ? "teal" : "red"}>
          {account.license.licenseStatus}
        </Badge>
        <Divider />
        <Text>
          {account.license.licenseType === "default" ||
          account.license.userLicenses === null
            ? "No license set - allowing for 20 user licenses"
            : account.license.userLicenses <= 0
            ? "Infinite user licenses"
            : `${account.license.userLicenses} user licenses`}
        </Text>
      </VStack>
    </ServiceDataSectionCard>
  );
}
