import { VStack, Text, Skeleton, Button } from "@chakra-ui/react";
import { api } from "../../../utilities/apiRequest";
import { downloadBlueprintExport } from "../../../utilities/blueprintExport";
import { BlueprintManual } from "../../../shared/v2/definitions/manuals";

type Props = {
  accountID: string;
  accountName: string;
};

export function ServiceAccountExportManual({ accountID, accountName }: Props) {
  const manualsQuery = api.admin.blueprint.exportManuals.useQuery({
    accountID,
  });

  const handleExportManual = (manual: BlueprintManual) => {
    if (!manualsQuery.isSuccess) return;
    downloadBlueprintExport([manual], {
      accountName,
      accountID,
      blueprintType: "manual",
      exportName: manual.name,
    });
  };

  return (
    <VStack>
      {manualsQuery.isLoading && (
        <>
          <Skeleton height="24px" width="100px" />
          <Skeleton height="24px" width="100px" />
          <Skeleton height="24px" width="100px" />
        </>
      )}
      {manualsQuery.isError && <Text>Oops</Text>}
      {manualsQuery.isSuccess && (
        <>
          <Text>Choose a manual to export</Text>
          {manualsQuery.data.manuals.map((manual) => (
            <Button
              variant="link"
              size="sm"
              key={manual.manualID}
              onClick={() => handleExportManual(manual)}>
              {manual.name}
            </Button>
          ))}
        </>
      )}
    </VStack>
  );
}
