import { Box, Text } from "@chakra-ui/react";

export function ServiceDataNoContent() {
  return (
    <Box py="1" w="100%">
      <Box bg="gray.100" w="100%" borderRadius="md" p="2" display="flex" justifyContent="center">
        <Text color="gray.500" fontSize="sm">None</Text>
      </Box>
    </Box>
  );
}
