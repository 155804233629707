import { Badge, Button, Divider, HStack, Text, VStack } from "@chakra-ui/react";
import { AdminPortalAccount } from "../../../../shared/v2/definitions/admin.types";
import { ServiceDataSectionCard } from "../ServiceDataSectionCard";
import { TagColorSchemMap } from "../../ServiceManageAccountsScreen";

type Props = {
  isFetching: boolean;
  account: AdminPortalAccount;
  onClickEditAccountName: () => void;
  onClickDeactivateAccount: () => void;
  onClickReactivateAccount: () => void;
  onClickDeleteAccount: () => void;
  onClickRestoreAccount: () => void;
};

export function ServiceAccountDetailsCard({
  isFetching,
  account,
  onClickEditAccountName,
  onClickDeactivateAccount,
  onClickReactivateAccount,
  onClickDeleteAccount,
  onClickRestoreAccount,
}: Props) {
  return (
    <ServiceDataSectionCard
      borderWidth="1px"
      borderStyle="solid"
      borderColor={
        account.accountStatus === "active"
          ? "transparent"
          : account.accountStatus === "deleted"
          ? "red.400"
          : "orange.400"
      }
      title="Account details"
      actions={
        <>
          {account.accountStatus !== "deleted" && (
            <Button
              onClick={onClickEditAccountName}
              isDisabled={isFetching}
              size="sm">
              Edit name
            </Button>
          )}
          {account.accountStatus === "active" && (
            <Button
              colorScheme="red"
              size="sm"
              isDisabled={isFetching}
              onClick={onClickDeactivateAccount}>
              Deactivate
            </Button>
          )}
          {account.accountStatus === "inactive" && (
            <Button
              colorScheme="orange"
              size="sm"
              isDisabled={isFetching}
              onClick={onClickReactivateAccount}>
              Reactivate
            </Button>
          )}
          {account.accountStatus === "inactive" && (
            <Button
              colorScheme="red"
              size="sm"
              isDisabled={isFetching}
              onClick={onClickDeleteAccount}>
              SCHEDULE FOR DELETION
            </Button>
          )}
          {account.accountStatus === "deleted" && (
            <Button
              colorScheme="orange"
              size="sm"
              isDisabled={isFetching}
              onClick={onClickRestoreAccount}>
              Attempt restoration
            </Button>
          )}
        </>
      }>
      <VStack w="100%" spacing="2" align="start">
        <Badge
          colorScheme={
            account.accountStatus === "active"
              ? "brand"
              : account.accountStatus === "deleted"
              ? "red"
              : "orange"
          }>
          {account.accountStatus}
        </Badge>
        <Divider />
        <Text>{account.accountName}</Text>

        <Text>
          {account.systemName ? account.systemName : "(No system name set)"}
        </Text>
      </VStack>
    </ServiceDataSectionCard>
  );
}
