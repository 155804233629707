import {
  Box,
  Button,
  Text as ChakraText,
  Flex,
  HStack,
  LinkBox,
  LinkOverlay,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalOverlay,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { Link as RouterLink } from "react-router-dom";
import { useAppState } from "../../../components/App/AppProvider";
import Icon from "../../../components/UI/Icon";
import MissingPermission from "../../../components/chakra/common/MissingPermission";
import Spinner from "../../../components/chakra/common/Spinner";
import {
  CustomFieldTypeIDToName,
  Date,
  Link,
  LongText,
  MultiselectList,
  Number,
  SingleSelectList,
  Text,
  Upload,
} from "../../../shared/v2/constants/CustomFieldTypeID";
import { useApiQuery } from "../../../utilities/apibelRequest";
import NewCustomFieldModal from "./NewCustomFieldModal";
import SettingsScreenContainer from "./SettingsScreenContainer";

type CustomFieldSummary = {
  customFieldID: string;
  name: string;
  typeID: string;
  units: string | null;
  helpText: string | null;
  mandatory: number;
};
type CustomFieldListSummary = {
  types: {
    name: string;
    id: string;
  }[];
  key: string;
}[];
const sortByName = (a: CustomFieldSummary, b: CustomFieldSummary) =>
  a.name?.localeCompare(b.name);
const sortByType = (a: CustomFieldSummary, b: CustomFieldSummary) =>
  CustomFieldTypeIDToName[a.typeID]?.localeCompare(
    CustomFieldTypeIDToName[b.typeID],
  );

const customFieldsList: CustomFieldListSummary = [
  {
    types: [
      {
        name: CustomFieldTypeIDToName[SingleSelectList],
        id: SingleSelectList,
      },
      {
        name: CustomFieldTypeIDToName[MultiselectList],
        id: MultiselectList,
      },
    ],
    key: "list",
  },
  {
    types: [
      {
        name: CustomFieldTypeIDToName[Text],
        id: Text,
      },
      {
        name: CustomFieldTypeIDToName[LongText],
        id: LongText,
      },
    ],
    key: "text",
  },
  {
    types: [
      {
        name: CustomFieldTypeIDToName[Number],
        id: Number,
      },
    ],
    key: "number",
  },
  {
    types: [
      {
        name: CustomFieldTypeIDToName[Date],
        id: Date,
      },
      {
        name: CustomFieldTypeIDToName[Link],
        id: Link,
      },
      {
        name: CustomFieldTypeIDToName[Upload],
        id: Upload,
      },
    ],
    key: "other",
  },
];

const CustomFieldsScreen = () => {
  const tableSize = useBreakpointValue({ base: "sm", lg: "md" }) as "md" | "lg";
  const { permissions } = useAppState().app;
  const hasAccountPermission = permissions.ACCOUNT_SETTINGS;
  const [sortBy, setSortBy] = useState<"name" | "type">("name");
  const newCustomFieldModal = useDisclosure();

  const [activeFieldType, setActiveFieldType] = useState<string | null>(null);

  const customFieldQuery = useApiQuery(
    "customFields/getAllCustomFields",
    null,
    {
      enabled: hasAccountPermission,
    },
  );
  const queryClient = useQueryClient();
  const handleClickRefresh = () => {
    queryClient.invalidateQueries({queryKey: ["customFields"]});
  };
  const finalList = customFieldQuery.isSuccess ? customFieldQuery.data : [];

  const handleModalOpen = (type: string) => {
    setActiveFieldType(type);
    newCustomFieldModal.onOpen();
  };

  const handleModalClose = () => {
    newCustomFieldModal.onClose();
    setActiveFieldType(null);
  };

  const renderRow = (customField: CustomFieldSummary) => (
    <Tr key={customField.customFieldID}>
      <LinkBox _hover={{ textDecor: "underline" }} as={Td}>
        <LinkOverlay
          ps="2"
          as={RouterLink}
          display="flex"
          alignItems="center"
          to={`/settings/customField/${customField.customFieldID}`}>
          <Flex flexDir="column" align="start" as="span">
            <span>{customField.name}</span>
          </Flex>
        </LinkOverlay>
      </LinkBox>
      <td>
        <Box>
          <Flex flexDir="column" align="start" as="span">
            <ChakraText as="span" fontSize="sm">
              {CustomFieldTypeIDToName[customField.typeID]}
            </ChakraText>
            {customField.mandatory === 1 && (
              <ChakraText as="sub" color="gray" fontSize="small">
                Mandatory
              </ChakraText>
            )}
          </Flex>
        </Box>
      </td>

      <Td align="center">{customField.units ? customField.units : "-"}</Td>
      <Td>
        <Tooltip label={customField.helpText}>
          <ChakraText fontSize="inherit" isTruncated maxW={48}>
            {customField.helpText}
          </ChakraText>
        </Tooltip>
      </Td>
    </Tr>
  );
  const sortedLists = finalList.sort(
    sortBy === "name" ? sortByName : sortByType,
  );

  return (
    <chakra-scope>
      <SettingsScreenContainer
        pageTitle="Custom Fields"
        headerEndContent={
          hasAccountPermission ? (
            <Menu>
              <MenuButton
                as={Button}
                colorScheme="brand"
                variant="solid"
                size="sm"
                rightIcon={<Icon name="ChevronDown" />}>
                Create New Custom Field
              </MenuButton>
              <MenuList>
                {customFieldsList.map(({ key, types }, idx) => (
                  <span key={key}>
                    {idx !== 0 && <MenuDivider />}
                    <MenuGroup key="key">
                      {types.map(({ name, id }) => (
                        <MenuItem onClick={() => handleModalOpen(id)} key={id}>
                          {name}
                        </MenuItem>
                      ))}
                    </MenuGroup>
                  </span>
                ))}
              </MenuList>
            </Menu>
          ) : (
            <> </>
          )
        }>
        {!hasAccountPermission ? (
          <MissingPermission />
        ) : (
          <VStack>
            <Box w="full">
              <Box w="full">
                <HStack justify="space-between">
                  <Menu size="sm">
                    <MenuButton
                      leftIcon={<Icon name="ArrowDown" />}
                      as={Button}
                      variant="ghost"
                      size="sm"
                      rightIcon={<Icon name="ChevronDown" />}>
                      Sort by {sortBy}
                    </MenuButton>
                    <MenuList zIndex="99">
                      <MenuItem onClick={() => setSortBy("name")}>
                        Name
                      </MenuItem>
                      <MenuItem onClick={() => setSortBy("type")}>
                        Type
                      </MenuItem>
                    </MenuList>
                  </Menu>

                  <Button
                    isLoading={customFieldQuery.isRefetching}
                    disabled={customFieldQuery.isFetching}
                    onClick={handleClickRefresh}
                    leftIcon={<Icon name="RefreshCw" />}
                    variant="ghost"
                    loadingText="Refreshing"
                    size="sm">
                    Refresh
                  </Button>
                </HStack>
              </Box>
              <TableContainer w="full" pt={2} pb={4}>
                <Table
                  minH="100"
                  variant="data-table"
                  size={tableSize}
                  position="relative">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Type</Th>
                      <Th>Units</Th>
                      <Th>Help Text</Th>
                    </Tr>
                  </Thead>

                  {customFieldQuery.isSuccess && (
                    <>
                      <Tbody
                        opacity={
                          customFieldQuery.isLoading ||
                          customFieldQuery.isFetching
                            ? 0.3
                            : 1
                        }>
                        {sortedLists.map(renderRow)}
                      </Tbody>
                      <Tfoot>
                        <Tr>
                          <Th>Name</Th>
                          <Th>Type</Th>
                          <Th>Units</Th>
                          <Th>Help Text</Th>
                        </Tr>
                      </Tfoot>
                    </>
                  )}
                  {(customFieldQuery.isLoading ||
                    customFieldQuery.isFetching) && (
                    <Spinner
                      zIndex={1}
                      opacity={1}
                      size="lg"
                      position="absolute"
                      top="50%"
                      left="50%"
                    />
                  )}
                </Table>
              </TableContainer>
            </Box>
          </VStack>
        )}
        <Modal
          motionPreset="slideInBottom"
          isCentered
          size="2xl"
          isOpen={newCustomFieldModal.isOpen && activeFieldType !== null}
          onClose={handleModalClose}>
          <ModalOverlay />
          {newCustomFieldModal.isOpen ? (
            <NewCustomFieldModal
              type={activeFieldType}
              onClose={handleModalClose}
            />
          ) : null}
        </Modal>
      </SettingsScreenContainer>
    </chakra-scope>
  );
};

export default CustomFieldsScreen;
