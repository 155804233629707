import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { useState } from "react";
import { CopyToClipboardIcon } from "../../../constants/commonIcons";
import { AdminPortalUser } from "../../../shared/v2/definitions/admin.types";
import { api } from "../../../utilities/apiRequest";
import useToast from "../../../utilities/useToast";

type ResetUserPasswordModalProps = {
  isOpen: boolean;
  onClose: () => void;
  user: AdminPortalUser;
};

export function ResetUserPasswordModal({
  isOpen,
  onClose,
  user,
}: ResetUserPasswordModalProps) {
  const resetPasswordMutation = api.admin.user.resetPassword.useMutation();
  const utils = api.useUtils();

  const { displayToast } = useToast();

  const [notifyUser, setNotifyUser] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [step, setStep] = useState<"confirm" | "complete">("confirm");

  const {
    value: shownPassword,
    setValue: setShownPassword,
    onCopy: onCopyPassword,
  } = useClipboard("");

  const handleClose = () => {
    setStep("confirm");
    setNotifyUser(false);
    setDisplayPassword(false);
    onClose();
  };

  const handleClickResetPassword = async () => {
    try {
      const res = await resetPasswordMutation.mutateAsync({
        userID: user.userID,
        displayPassword,
        notifyUser,
      });
      setShownPassword(typeof res.password === "string" ? res.password : "");
      setStep("complete");
    } catch (e) {
      console.error(e);
      displayToast({
        status: "error",
        title: "Failed to reset password",
      });
    }
  };

  const handleClickComplete = () => {
    utils.admin.user.invalidate();
    handleClose();
  };

  return (
    <Modal
      motionPreset="slideInBottom"
      size="2xl"
      isOpen={isOpen}
      onClose={handleClose}>
      <ModalOverlay />

      <ModalContent>
        <chakra-scope>
          <ModalHeader>Confirm Password Reset</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              handleClose();
            }}
          />
          <ModalBody>
            {step === "confirm" ? (
              <>
                <Text>
                  You are about to reset the password of{" "}
                  <Box as="span" fontWeight="bold" fontStyle="italic">
                    {user.firstName} {user.lastName}{" "}
                  </Box>
                </Text>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="isEmailed" mb="0">
                    Email password to user?
                  </FormLabel>
                  <Switch
                    id="email-alerts"
                    isChecked={notifyUser}
                    onChange={(e) => setNotifyUser(e.target.checked)}
                  />
                </FormControl>
                <FormControl display="flex" alignItems="center">
                  <FormLabel htmlFor="email-alerts" mb="0">
                    Show me the password?
                  </FormLabel>
                  <Switch
                    id="isShown"
                    isChecked={displayPassword}
                    onChange={(e) => setDisplayPassword(e.target.checked)}
                  />
                </FormControl>
                <ModalFooter>
                  <Button
                    colorScheme="gray"
                    mr={3}
                    isDisabled={resetPasswordMutation.isPending}
                    onClick={handleClose}>
                    Close
                  </Button>
                  <Tooltip
                    label={displayPassword || notifyUser ? null : "👉👈"}>
                    <Button
                      isDisabled={!(displayPassword || notifyUser)}
                      loadingText="Resetting..."
                      isLoading={resetPasswordMutation.isPending}
                      onClick={handleClickResetPassword}>
                      Reset
                    </Button>
                  </Tooltip>
                </ModalFooter>
              </>
            ) : (
              <>
                {shownPassword.length >= 1 && (
                  <InputGroup>
                    <Input value={shownPassword || ""} pr="10" isReadOnly />
                    <InputRightElement width="10">
                      <IconButton
                        variant="outline"
                        colorScheme="gray"
                        aria-label="Copy to clipboard"
                        icon={<Icon as={CopyToClipboardIcon} />}
                        onClick={onCopyPassword}
                      />
                    </InputRightElement>
                  </InputGroup>
                )}
                <Text>
                  The password of {user.firstName} has been reset successfully.
                </Text>
                <ModalFooter>
                  <Button onClick={handleClickComplete}>Complete</Button>
                </ModalFooter>
              </>
            )}
          </ModalBody>
        </chakra-scope>
      </ModalContent>
    </Modal>
  );
}
