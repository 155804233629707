import { useState } from "react";

const localStorageKey = "screen-exp";

function getDefaultValueFromLocalStorage(): boolean {
  const res = localStorage.getItem(localStorageKey);
  return res === "true"
}

function setValueToLocalStorage(value: boolean) {
  localStorage.setItem(localStorageKey, value ? "true" : "false");
}

export function useScreenExpansion() {
  const [screenExpansion, setScreenExpansion] = useState(
    getDefaultValueFromLocalStorage,
  );

  const onChange = (value: boolean) => {
    setValueToLocalStorage(value);
    setScreenExpansion(value);
  };

  return [screenExpansion, onChange] as const;
}
