import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Route, Routes, useMatch, useSearchParams } from "react-router-dom";

// Mantine styles
import "@mantine/core/styles.css";
import "@mantine/dropzone/styles.css";

import "./App.css";
import { useActiveAppState } from "./components/App/AppProvider";
import LicenseFeeCalculator from "./components/chakra/LicenseFeeCalculator";
import CustomSidebar from "./components/login/CustomSidebar";
import { Sidebar } from "./components/UI/Sidebar/Sidebar";
import SidebarStateManager from "./components/UI/Sidebar/SidebarStateManager";
import ManualRoutes from "./screens/_chakra/manual/ManualRoutes";
import RegisterDashboardScreen from "./screens/_chakra/RegisterDashboardScreen";
import RequirementDashboardScreen from "./screens/_chakra/RequirementDashboardScreen";
import CustomFieldsScreen from "./screens/_chakra/settings/CustomFieldsScreen";
import ListScreen from "./screens/_chakra/settings/ListsScreen";
import ProfileScreen from "./screens/_chakra/settings/ProfileScreen";
import RolesScreen from "./screens/_chakra/settings/RolesScreen";
import UsersScreen from "./screens/_chakra/settings/UsersScreen";
import { ScreensAction } from "./screens/Action/Action";
import { ScreensActionDashboard } from "./screens/Action/Dashboard";
import { ScreensOnDemandAction } from "./screens/Action/OnDemandAction";
import { ScreensSingleCustomField } from "./screens/CustomFields/SingleCustomField";
import { ScreensDocuments } from "./screens/Documents/Documents";
import { ErrorBoundary } from "./screens/ErrorBoundary/ErrorBoundary";
import { ScreensExport } from "./screens/Export/Export";
import { HistoryScreen } from "./screens/History/History";
import { ScreensHome } from "./screens/Home/Home";
import HomeDashboardScreen from "./screens/Home/HomeDashboardScreen";
import { ScreensInstance } from "./screens/Instance/Instance";
import { ScreensIssueDashboard } from "./screens/Issue/Dashboard";
import { ScreensJobDashboard } from "./screens/Job/Dashboard";
import { ScreensSingleList } from "./screens/List/SingleList";
import MainLayout from "./screens/MainLayout";
import NotFoundScreen from "./screens/NotFound";
import { ScreensPageDocuments } from "./screens/Pages/PageDocuments";
import { ScreensPageFields } from "./screens/Pages/PageFields";
import { ScreensPages } from "./screens/Pages/Pages";
import { ScreensPageSettings } from "./screens/Pages/PageSettings";
import CSVUploaderScreen from "./screens/Register/CSVUploader";
import { ScreensRegisterDashboard as LegacyRegisterDashboardScreen } from "./screens/Register/Dashboard";
import MassUploadScreen from "./screens/Register/MassUploadRegisters";
import RegisterPagesScreen from "./screens/Register/RegisterPagesScreen";
import RegisterRecordScreen from "./screens/Register/RegisterRecordScreen";
import ReportRoutes from "./screens/Report/ReportRoutes";
import { ScreensRequirement } from "./screens/Requirement/Requirement";
import { ScreensRiskDashboard } from "./screens/Risk/Dashboard";
import { ScreensRiskMatrix } from "./screens/RiskMatrix/RiskMatrix";
import { ScreensAccount } from "./screens/Settings/Account";
import { ScreensAccountCalendar } from "./screens/Settings/AccountCalendar";
import { ScreensAccountExcludedDate } from "./screens/Settings/ExcludedDate";
import { Integrations } from "./screens/Settings/Integrations/Integrations";
import { ScreensSingleRole } from "./screens/Settings/SingleRole";
import { SingleUserScreen } from "./screens/Settings/SingleUser";
import useSystemMode from "./utilities/useSystemMode";

/**
 * Renders all the routes, + the sidebar and header ect.
 * Expects to be authenticated
 */
export function ProtectedMainRoutes() {
  const [sidebarWidth, setSidebarWidth] = useState(325);
  const { appState } = useActiveAppState();
  const [searchParams] = useSearchParams();
  const isTemplate = useSystemMode() === "template";
  const sidebarDrawerState = useDisclosure({ defaultIsOpen: false });

  // Check if were a legacy page that needs to display the sidebar still
  const isLegacyRisk = !!useMatch("/risk/*");
  const isLegacyReport = !!useMatch("/report/*");
  const isLegacyIssue = !!useMatch("/issue/*");
  // const isLegacyRegister = !!useMatch("/register/*");
  // const isLegacyRequirement = !!useMatch("/requirement/*");
  const isLegacyPage = isLegacyIssue || isLegacyReport || isLegacyRisk;

  const shouldUseSidebarDrawer = useBreakpointValue({ base: true, lg: false });

  const displayParam = searchParams.get("display");

  const shouldDisplaySidebar =
    isTemplate || (displayParam && displayParam.length >= 1) || isLegacyPage;

  const showDevTools = appState.app.userInfo.isInternal;

  return (
    <SidebarStateManager>
      <MainLayout
        handleClickSidebarDrawer={sidebarDrawerState.onOpen}
        showSidebarDrawerButton={Boolean(
          shouldDisplaySidebar && shouldUseSidebarDrawer,
        )}>
        {shouldDisplaySidebar && shouldUseSidebarDrawer && (
          <Drawer
            size="md"
            isOpen={sidebarDrawerState.isOpen}
            onClose={sidebarDrawerState.onClose}
            placement="left">
            <DrawerOverlay />
            <chakra-scope>
              <DrawerContent>
                <DrawerBody>
                  <Sidebar
                    onSignOut={() => {}}
                    closeSidebar={sidebarDrawerState.onClose}
                    openSidebar={sidebarDrawerState.onOpen}
                    mobileIsOpen={sidebarDrawerState.isOpen}
                    setMobileOpen={sidebarDrawerState.onOpen}
                    isInDrawer
                  />
                </DrawerBody>
              </DrawerContent>
            </chakra-scope>
          </Drawer>
        )}
        <Row style={{ margin: 0 }}>
          {shouldDisplaySidebar && !shouldUseSidebarDrawer && (
            <Col
              sm="auto"
              className="sidebar-col"
              style={{ position: "fixed", padding: 0 }}>
              <CustomSidebar
                width={sidebarWidth}
                onWidthChange={setSidebarWidth}
                onSignOut={() => {}}
                renderSidebar
              />
            </Col>
          )}
          <Col
            style={{
              paddingRight: 0,
              paddingLeft: 0,
              marginLeft: shouldDisplaySidebar
                ? shouldUseSidebarDrawer
                  ? "8px"
                  : sidebarWidth
                : 0,
            }}>
            <ErrorBoundary>
              <Routes>
                <Route path="manual/*" element={<ManualRoutes />} />
                <Route
                  path="instance/:instanceID"
                  element={<ScreensInstance />}
                />
                <Route path="action/:actionID" element={<ScreensAction />} />
                {showDevTools && (
                  <Route path="__pricing" element={<LicenseFeeCalculator />} />
                )}
                <Route
                  path="action?:category"
                  element={<ScreensActionDashboard />}
                />
                <Route path="action" element={<ScreensActionDashboard />} />
                <Route
                  path="requirement/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="requirement"
                  element={<RequirementDashboardScreen />}
                />
                <Route
                  path="ondemandaction/:requirementOnDemandActionID"
                  element={<ScreensOnDemandAction />}
                />
                <Route
                  path="issue/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route path="issue" element={<ScreensIssueDashboard />} />
                <Route
                  path="history/:referenceID"
                  element={<HistoryScreen />}
                />
                <Route path="history" element={<HistoryScreen />} />

                <Route
                  path="register/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route path="register" element={<RegisterPagesScreen />} />
                <Route path="registernew" element={<RegisterPagesScreen />} />
                <Route
                  path="register/dashboard/:objectTypeID"
                  element={<RegisterDashboardScreen />}
                />
                <Route
                  path="register/legacy-dashboard"
                  element={<LegacyRegisterDashboardScreen />}
                />
                <Route
                  path="registernew/:recordID"
                  element={<RegisterRecordScreen />}
                />
                <Route
                  path="register/upload/:registerID"
                  element={<CSVUploaderScreen />}
                />
                <Route path="register/upload" element={<MassUploadScreen />} />

                <Route
                  path="risk/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route path="risk" element={<ScreensRiskDashboard />} />
                <Route path="report/*" element={<ReportRoutes />} />
                <Route
                  path="job/:requirementID"
                  element={<ScreensRequirement />}
                />

                <Route path="settings" element={<ScreensAccount />} />
                <Route path="settings/account" element={<ScreensAccount />} />
                <Route
                  path="settings/customfields"
                  element={<CustomFieldsScreen />}
                />
                <Route
                  path="settings/customField/:customFieldID"
                  element={<ScreensSingleCustomField />}
                />
                <Route
                  path="settings/role/:roleID"
                  element={<ScreensSingleRole />}
                />
                <Route path="settings/users" element={<UsersScreen />} />
                <Route path="settings/roles" element={<RolesScreen />} />

                <Route path="settings/profile" element={<ProfileScreen />} />
                <Route
                  path="settings/user/:userID"
                  element={<SingleUserScreen />}
                />
                <Route path="settings/pages" element={<ScreensPages />} />
                <Route
                  path="settings/pages/fields"
                  element={<ScreensPageFields />}
                />
                <Route
                  path="settings/pages/documents"
                  element={<ScreensPageDocuments />}
                />
                <Route
                  path="settings/account/calendar/excludeddate"
                  element={<ScreensAccountExcludedDate />}
                />
                <Route
                  path="settings/account/calendar"
                  element={<ScreensAccountCalendar />}
                />
                <Route path="settings/lists" element={<ListScreen />} />
                <Route
                  path="settings/list/documents"
                  element={<ScreensDocuments />}
                />
                <Route
                  path="settings/list/:listID"
                  element={<ScreensSingleList />}
                />

                <Route
                  path="settings/riskMatrix"
                  element={<ScreensRiskMatrix />}
                />
                <Route
                  path="settings/integrations"
                  element={<Integrations />}
                />
                <Route path="export/:exportID" element={<ScreensExport />} />

                <Route path="job" element={<ScreensJobDashboard />} />

                <Route
                  path="settings/pages/settings"
                  element={<ScreensPageSettings />}
                />

                <Route
                  path="template/requirement/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/register/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/issue/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/risk/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/job/:requirementID"
                  element={<ScreensRequirement />}
                />
                <Route
                  path="template/action/:actionID"
                  element={<ScreensAction />}
                />

                <Route path="template/*" element={<ScreensHome />} />

                <Route index element={<HomeDashboardScreen />} />
                <Route path="*" element={<NotFoundScreen />} />
              </Routes>
            </ErrorBoundary>
          </Col>
        </Row>
      </MainLayout>
    </SidebarStateManager>
  );
}
