import {
  Button,
  chakra,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Skeleton,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "@tanstack/react-query";
import ShortTextField from "../../../components/chakra/form/formComponents/ShortTextField";
import { NewUserSchema } from "../../../shared/v2/validators/userSchemas";
import {
  isAxiosError,
  post,
  useApiQuery,
} from "../../../utilities/apibelRequest";
import useToast from "../../../utilities/useToast";
import { useAuth } from "../../../utilities/useAuth";

type Props = {
  onClose: () => void;
};

const NewUserModal = ({ onClose }: Props) => {
  const { getToken } = useAuth();

  const { displayToast } = useToast();
  const queryClient = useQueryClient();
  const rolesResult = useApiQuery("role/allRoles", null);
  const form = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    resolver: zodResolver(NewUserSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      roles: [],
    },
  });
  const { handleSubmit, formState, getValues } = form;

  const onSubmit = async () => {
    const { email, firstName, lastName, roles } = getValues();
    try {
      await post("user/invite", {
        email: email.trim(),
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        roles,
      }, getToken);
      displayToast({
        title: `User invited successfully`,
        status: "success",
      });
    } catch (error) {
      if (
        isAxiosError(error) &&
        error.response?.data.error === "USER_ALREADY_EXISTS"
      ) {
        displayToast({
          title: "Failed to invite user",
          description: "User already exists in this account",
          status: "error",
        });
      } else {
        displayToast({
          title: "Failed to invite user",
          status: "error",
        });
      }
    }
    queryClient.invalidateQueries({queryKey: ["user"]});
    onClose();
  };
  return (
    <chakra-scope>
      <ModalHeader>Invite User</ModalHeader>
      <ModalCloseButton />
      {rolesResult.isFetching || rolesResult.isLoading ? (
        <>
          <ModalBody>
            <VStack align="start" spacing={4} w="full">
              <Skeleton h="4" w="40%" />
              <Skeleton h="10" w="full" />
              <HStack spacing={4} w="full" align="start">
                <VStack w="full" align="start">
                  <Skeleton h="4" w="40%" />
                  <Skeleton h="10" w="full" />
                </VStack>
                <VStack w="full" align="start">
                  <Skeleton h="4" w="40%" />
                  <Skeleton h="10" w="full" />
                </VStack>
              </HStack>
              <Skeleton h="4" w="40%" />
              <Skeleton h="4" w="40%" />
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack>
              <Button onClick={onClose} colorScheme="gray">
                Cancel
              </Button>
              <Button disabled>Invite</Button>
            </HStack>
          </ModalFooter>
        </>
      ) : !rolesResult.isSuccess ? (
        <Text>Failed to fetch roles</Text>
      ) : (
        <FormProvider {...form}>
          <chakra.form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <VStack align="start">
                <ShortTextField
                  name="email"
                  fieldProps={{ label: "Email", isRequired: true }}
                />
                <HStack w="full" align="start">
                  <ShortTextField
                    name="firstName"
                    fieldProps={{
                      label: "First Name",
                      maxLength: 64,
                      isRequired: true,
                    }}
                  />
                  <ShortTextField
                    name="lastName"
                    fieldProps={{
                      label: "Last Name",
                      maxLength: 64,
                      isRequired: true,
                    }}
                  />
                </HStack>
                <Controller
                  name="roles"
                  render={({ fieldState, field }) => (
                    <FormControl isInvalid={!!fieldState.error}>
                      <FormLabel>Roles</FormLabel>
                      <CheckboxGroup
                        onChange={field.onChange}
                        value={field.value}
                      >
                        <VStack ps={4} spacing={0} align="start">
                          {rolesResult.data.map(
                            ({ roleID, name, description }) => (
                              <Checkbox
                                key={roleID}
                                marginBottom={0}
                                value={roleID}
                              >
                                <Tooltip openDelay={500} label={description}>
                                  <chakra.span>{name}</chakra.span>
                                </Tooltip>
                              </Checkbox>
                            ),
                          )}
                        </VStack>
                      </CheckboxGroup>
                      <FormHelperText>
                        Make sure you give at least one role. Users with no
                        roles won&apos;t be able to access the system.
                      </FormHelperText>
                      <FormErrorMessage>
                        {fieldState.error && fieldState.error.message}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                />
              </VStack>
            </ModalBody>
            <ModalFooter>
              <HStack>
                <Button
                  disabled={formState.isSubmitting}
                  onClick={onClose}
                  colorScheme="gray"
                >
                  Cancel
                </Button>
                <Button
                  isLoading={formState.isSubmitting}
                  loadingText="Inviting"
                  type="submit"
                >
                  Invite
                </Button>
              </HStack>
            </ModalFooter>
          </chakra.form>
        </FormProvider>
      )}
    </chakra-scope>
  );
};

export default NewUserModal;
