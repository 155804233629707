import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from "@chakra-ui/react";
import { AdminPortalAccount } from "../../../shared/v2/definitions/admin.types";
import { api } from "../../../utilities/apiRequest";
import useToast from "../../../utilities/useToast";

type ConfirmDeleteAccountModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
  account: AdminPortalAccount;
};

export function ConfirmDeleteAccountModal({
  isOpen,
  onClose,
  account,
  onRefresh,
}: ConfirmDeleteAccountModalProps) {
  const deleteAccountMutation = api.admin.account.deleteByID.useMutation();

  const { displayToast } = useToast();

  const handleClickDeleteAccount = async () => {
    try {
      const res = await deleteAccountMutation.mutateAsync({
        accountID: account.accountID,
      });
      onRefresh();
      onClose();
    } catch (e) {
      console.error(e);
      displayToast({
        status: "error",
        title: "Failed to delete account",
      });
    }
  };

  return (
    <Modal
      motionPreset="slideInBottom"
      size="2xl"
      isOpen={isOpen}
      onClose={onClose}>
      <ModalOverlay />

      <ModalContent>
        <chakra-scope>
          <ModalHeader>Schedule account for deletion</ModalHeader>
          <ModalCloseButton onClick={onClose} />
          <ModalBody>
            <VStack align="start">
              <Text>
                Are you sure you want to schedule this account for deletion?
              </Text>
              <Text>
                This can{" "}
                <Box as="span" fontWeight="700" fontStyle="italic">
                  probably
                </Box>{" "}
                be undone.
              </Text>
              <Text>
                The account will be deleted next time the account deletion tool
                runs. (Which is actually currently never but sometimes liam
                might do it manually?)
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              isDisabled={deleteAccountMutation.isPending}
              onClick={onClose}>
              Cancel
            </Button>
            <Button
            colorScheme="red"
              loadingText="Deleting"
              isLoading={deleteAccountMutation.isPending}
              onClick={handleClickDeleteAccount}>
              Delete account
            </Button>
          </ModalFooter>
        </chakra-scope>
      </ModalContent>
    </Modal>
  );
}
