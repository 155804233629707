import {
  Button,
  chakra,
  Divider,
  HStack,
  Icon,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Portal,
  Progress,
  Tooltip,
  useColorMode,
  useDisclosure,
} from "@chakra-ui/react";
import { mdiMenu } from "@mdi/js";
import MdiIcon from "@mdi/react";
import { useIsFetching } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import {
  RiAdminLine,
  RiArrowDownSLine,
  RiExternalLinkLine,
  RiFileUserLine,
  RiGroupLine,
  RiInputMethodLine,
  RiLayoutGridLine,
  RiListUnordered,
  RiMoonLine,
  RiPagesLine,
  RiQuestionLine,
  RiSearchLine,
  RiSettings3Line,
  RiSunLine,
  RiUser3Line
} from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../components/App/AppProvider";
import ConfirmEnterTemplateManagementDialog from "../components/chakra/ConfirmEnterTemplateManagementDialog";
import ActionNavItem from "../components/chakra/navigation/ActionNavItem";
import IssueNavItem from "../components/chakra/navigation/IssueNavItem";
import RegisterNavItem from "../components/chakra/navigation/RegisterNavItem";
import TopBarButton from "../components/chakra/navigation/TopBarButton";
import TopBarOverflow, {
  useTopBarNavItemsStage,
} from "../components/chakra/navigation/TopBarOverflow";
import { NotificationMenu } from "../components/chakra/notifications/NotificationMenu";
import AccountLogo from "../components/UI/AccountLogo";
import QuickSearch from "../components/UI/QuickSearch";
import TopbarAccount from "../components/UI/Sidebar/TopbarAccount";
import {
  HomeIcon,
  ManualIcon,
  ReportIcon,
  RequirementIcon,
  RiskIcon,
  TemplateManagementIcon,
} from "../constants/commonIcons";
import isJobsAccount from "../constants/JobsVisibleCheck";
import headerImgBrandmark from "../images/logo_brandmark.png";

const versionNumberString = `CertCrowd v${import.meta.env.VITE_VERSION}.${
  import.meta.env.VITE_COMMIT_HASH
}`;

type ProgState =
  | {
      visible: false;
    }
  | {
      visible: true;
      prog: number;
    };

function TopBarLoader({ isFetching }: { isFetching: boolean }) {
  const [state, setState] = useState<ProgState>({ visible: false });

  // Timeout function
  useEffect(() => {
    if (isFetching) {
      // We started fetching, start showing the progress bar
      setState((prev) => ({
        visible: true,
        prog: prev.visible ? prev.prog : Math.random() * 0.2 + 0.05, // Set to 0.05-0.25
      }));
      const timeout = setInterval(() => {
        setState((prev) => {
          if (prev.visible) {
            const diff = 100 - prev.prog;
            const next = prev.prog + diff * (Math.random() * 0.3 + 0.1);
            return {
              visible: true,
              prog: next, // Add up to 50% of remainder each 500ms tick
            };
          }
          return prev;
        });
      }, 500);
      return () => clearInterval(timeout);
    }
    // Not fetching, start finishing up the progress bar
    setState({ visible: true, prog: 100 });
    const timeout = setTimeout(() => {
      setState({ visible: false });
    }, 500);
    return () => {
      setState({ visible: false });

      clearTimeout(timeout);
    };
  }, [isFetching]); // Run whenever isFetching changes

  return state.visible ? (
    <Progress
      sx={{
        "&>div": {
          transition: "width 450ms",
          transitionTimingFunction: "ease-in-out",
        },
      }}
      bg="transparent"
      h="2px"
      size="xs"
      value={state.prog}
      w="full"
    />
  ) : (
    <chakra.div />
  );
}

type Props = {
  showSidebarDrawerButton: boolean;
  handleClickSidebarDrawer: () => void;
};

const SettingsMenu = ({
  onClickTemplates,
}: {
  onClickTemplates: () => void;
}) => {
  const appState = useAppState();
  const { app } = appState;
  const navigate = useNavigate();
  const { toggleColorMode, colorMode } = useColorMode();

  return (
    <Portal>
      <chakra-scope>
        <MenuList zIndex={12}>
          <MenuGroup
            color="primary1.main"
            fontSize="sm"
            title={`${app.userInfo.firstName} ${app.userInfo.lastName}`}>
            <MenuItem
              icon={<Icon boxSize={4} as={RiUser3Line} />}
              onClick={() => navigate("settings/profile")}>
              Your Profile
            </MenuItem>
            <MenuDivider />
          </MenuGroup>
          <MenuGroup title="Account Settings">
            <MenuItem
              icon={<Icon boxSize={4} as={RiAdminLine} />}
              onClick={() => navigate("settings/account")}>
              Account & Calendar
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize={4} as={RiGroupLine} />}
              onClick={() => navigate("settings/users")}>
              Users
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize={4} as={RiFileUserLine} />}
              onClick={() => navigate("settings/roles")}>
              Roles
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup title="System Config">
            <MenuItem
              icon={<Icon boxSize={4} as={RiPagesLine} />}
              onClick={() => navigate("settings/pages")}>
              Pages
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize={4} as={RiInputMethodLine} />}
              onClick={() => navigate("settings/customfields")}>
              Custom Fields
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize={4} as={RiListUnordered} />}
              onClick={() => navigate("settings/lists")}>
              Lists
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize={4} as={RiLayoutGridLine} />}
              onClick={() => navigate("settings/riskmatrix")}>
              Risk Matrix
            </MenuItem>
            <MenuItem
              icon={<Icon boxSize={4} as={TemplateManagementIcon} />}
              onClick={onClickTemplates}>
              Template Management
            </MenuItem>
          </MenuGroup>
          {isJobsAccount(appState) ? (
            <MenuItem onClick={() => navigate("settings/integrations")}>
              Integrations
            </MenuItem>
          ) : null}
          {app.userInfo.isInternal && (
            <MenuItem
              icon={
                colorMode === "dark" ? (
                  <Icon as={RiSunLine} />
                ) : (
                  <Icon as={RiMoonLine} />
                )
              }
              onClick={toggleColorMode}>
              {colorMode === "dark"
                ? "Revert to light mode"
                : "Use Dark Mode (Experimental)"}
            </MenuItem>
          )}
        </MenuList>
      </chakra-scope>
    </Portal>
  );
};

const TopBar = ({
  showSidebarDrawerButton,
  handleClickSidebarDrawer,
}: Props) => {
  // const { colorMode, toggleColorMode } = useColorMode();
  const {app} = useAppState();
  const searchModalState = useDisclosure({ defaultIsOpen: false });
  const templateManagementDialogState = useDisclosure({ defaultIsOpen: false });
  const isFetching = useIsFetching();
  const numNavItemsToShow = useTopBarNavItemsStage();

  const handleEnterTemplateManagement = () => {
    window.location.href = "/template";
  };

  return (
    <>
      <HStack
        alignItems="center"
        spacing={numNavItemsToShow >= 1 ? 6 : 2}
        justifyContent="space-between"
        w="full"
        h="full"
        pos="relative">
        <chakra.div
          bg="transparent"
          left="0"
          bottom="0"
          w="full"
          pos="absolute">
          <TopBarLoader isFetching={isFetching >= 1} />
        </chakra.div>
        <HStack h="full" spacing={numNavItemsToShow >= 1 ? 6 : 2}>
          <HStack h="full" spacing="2" ps={numNavItemsToShow >= 1 ? 4 : 0}>
            {showSidebarDrawerButton && (
              <IconButton
                aria-label="Open Sidebar"
                mt={1}
                ml={1}
                variant="ghost"
                icon={<MdiIcon size={1} path={mdiMenu} />}
                onClick={handleClickSidebarDrawer}
              />
            )}
            <Menu closeOnSelect={false} placement="bottom" isLazy>
              <MenuButton
                as={Button}
                rightIcon={<Icon as={RiArrowDownSLine} />}
                variant="ghost"
                colorScheme="gray">
                <HStack ps="2" h="full" spacing="4">
                  <AccountLogo
                    maxH={8}
                    minW={12}
                    maxW={numNavItemsToShow >= 1 ? 32 : 16}
                  />
                </HStack>
              </MenuButton>
              <TopbarAccount />
            </Menu>
          </HStack>
          <Divider orientation="vertical" />
          <HStack h="full">
            {numNavItemsToShow >= 2 && (
              <TopBarButton
                to="/"
                leftIcon={<Icon boxSize="4" as={HomeIcon} />}>
                Home
              </TopBarButton>
            )}
            {numNavItemsToShow >= 3 && (
              <TopBarButton
                to="/requirement"
                leftIcon={<Icon boxSize="4" as={RequirementIcon} />}>
                Requirements
              </TopBarButton>
            )}
            {numNavItemsToShow >= 4 && <RegisterNavItem />}
            {numNavItemsToShow >= 5 && <ActionNavItem />}
            {numNavItemsToShow >= 6 && <IssueNavItem />}
            {numNavItemsToShow >= 7 && (
              <TopBarButton
                leftIcon={<Icon boxSize="4" as={RiskIcon} />}
                to="risk?display=risks">
                Risk
              </TopBarButton>
            )}
            {numNavItemsToShow >= 8 && (
              <TopBarButton
                leftIcon={<Icon boxSize="4" as={ManualIcon} />}
                to="manual">
                Manual
              </TopBarButton>
            )}
            {numNavItemsToShow >= 7 && (
              <TopBarButton
                leftIcon={<Icon boxSize="4" as={ReportIcon} />}
                to="report?display=reports">
                Reports
              </TopBarButton>
            )}
            {numNavItemsToShow < 7 && numNavItemsToShow >= 1 && (
              <TopBarOverflow />
            )}
          </HStack>
        </HStack>
        <HStack h="full" spacing="1">
          {numNavItemsToShow <= 0 && <TopBarOverflow />}
          {numNavItemsToShow >= 1 && <Divider orientation="vertical" />}
          <Tooltip label="Quick Search">
            <Button size="sm" variant="ghost" onClick={searchModalState.onOpen}>
              <span>
                <Icon boxSize="4" as={RiSearchLine} />
              </span>
            </Button>
          </Tooltip>
          <Menu placement="bottom" strategy="absolute" isLazy>
            <Tooltip label="Configuration">
              <MenuButton as={Button} variant="ghost" size="sm">
                <Icon boxSize="4" as={RiSettings3Line} />
              </MenuButton>
            </Tooltip>
            <SettingsMenu
              onClickTemplates={templateManagementDialogState.onOpen}
            />
            <ConfirmEnterTemplateManagementDialog
              state={templateManagementDialogState}
              onConfirm={handleEnterTemplateManagement}
            />
          </Menu>

          {/* <b /> */}
          {/* <Tooltip label="Toggle Theme">
                <Button variant="ghost" onClick={toggleColorMode}>
                  <Icon name={colorMode === "light" ? "Sun" : "Moon"} />
                </Button>
              </Tooltip> */}
          {numNavItemsToShow >= 3 && (
            <>
              <NotificationMenu />
              <Menu placement="bottom">
                <Tooltip label="Help">
                  <MenuButton as={Button} variant="ghost" size="sm">
                    <Icon boxSize="4" as={RiQuestionLine} />
                  </MenuButton>
                </Tooltip>
                <MenuList>
                  <MenuGroup title={versionNumberString}>
                    <MenuItem
                      as="a"
                      target="_blank"
                      icon={<Icon as={RiExternalLinkLine} />}
                      href="https://help.certcrowd.com/docs/intro">
                      Using CertCrowd
                    </MenuItem>
                    <MenuItem
                      as="a"
                      target="_blank"
                      icon={<Icon as={RiExternalLinkLine} />}
                      href="https://www.certcrowd.com/tools/">
                      Quickstart Guides
                    </MenuItem>
                    <MenuItem
                      as="a"
                      target="_blank"
                      icon={<Icon as={RiExternalLinkLine} />}
                      href="https://help.certcrowd.com/changelog/">
                      Changelog
                    </MenuItem>
                  </MenuGroup>
                </MenuList>
              </Menu>
            </>
          )}
          {numNavItemsToShow >= 1 && (
            <Image
              maxH={6}
              minW={12}
              maxW={6}
              fit="contain"
              src={headerImgBrandmark}
            />
          )}
        </HStack>
      </HStack>
      <Modal
        motionPreset="slideInBottom"
        isOpen={searchModalState.isOpen}
        onClose={searchModalState.onClose}
        size="lg">
        <chakra-scope>
          <ModalOverlay />
          <ModalContent>
            <ModalBody>
              <QuickSearch onCloseModal={searchModalState.onClose} />
            </ModalBody>
          </ModalContent>
        </chakra-scope>
      </Modal>
    </>
  );
};

export default TopBar;
