import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  Heading,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { generateHTML } from "@tiptap/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { RiAddLine, RiPencilLine } from "react-icons/ri";
import { useNavigate, useParams } from "react-router-dom";
import { useAppState } from "../../../components/App/AppProvider";
import Breadcrumbs from "../../../components/chakra/Breadcrumbs";
import FullLoader from "../../../components/chakra/FullLoader";
import { DeleteIcon, ExportIcon } from "../../../constants/commonIcons";
import { ManualSectionData } from "../../../shared/v2/definitions/manuals";
import { useApiMutation, useApiQuery } from "../../../utilities/apibelRequest";
import useToast from "../../../utilities/useToast";
import AddSectionModal from "./components/AddSectionModal";
import { ConfirmDeleteModal } from "./components/ConfirmDeleteModal";
import EditManualNameModal from "./components/EditManualNameModal";
import ManualDetailsCard from "./components/ManualDetailsCard";
import ManualSection from "./components/ManualSection";
import TableOfContents from "./components/TableOfContents";
import { getEditorExtensions } from "./components/useEditorWithExtensions";
import { ViewUsageModal } from "./components/ViewUsageModal";

function findActiveHeading(rects: DOMRect[]) {
  if (rects.length === 0) {
    return -1;
  }

  const offset = 48;

  const closest = rects.reduce(
    (acc, item, index) => {
      if (Math.abs(acc.position) < Math.abs(item.y) - offset) {
        return acc;
      }

      return {
        index,
        position: item.y,
      };
    },
    { index: 0, position: (rects[0]?.y || 0) - offset },
  );

  return closest.index;
}

const getSectionHTMLID = (section: ManualSectionData) =>
  `${encodeURIComponent(
    section.title.slice(0, 24),
  )}-${section.manualSectionID.slice(0, 8)}`;

export default function ManualScreen() {
  const navigate = useNavigate();
  const params = useParams();
  const { manualID } = params;

  const { app } = useAppState();

  const manualQuery = useApiQuery(
    "manual/byID",
    {
      manualID: manualID || "",
    },
    { enabled: !!manualID },
  );

  const sectionsQuery = useApiQuery(
    "manual/manySections",
    {
      manualID: manualID || "",
      contentType: "full",
    },
    { enabled: !!manualID },
  );
  const exportToPDF = useApiMutation("manual/exportToPDF");
  const exportToDOCX = useApiMutation("manual/exportToDOCX");

  const createSectionMutation = useApiMutation("manual/createSection");
  const editManualNameMutation = useApiMutation("manual/update");
  const deleteManualSectionMutation = useApiMutation("manual/deleteSection");
  const deleteManualMutation = useApiMutation("manual/deleteManual");

  const isLoading = manualQuery.isLoading || sectionsQuery.isLoading;
  const isFetching = manualQuery.isFetching || sectionsQuery.isFetching;
  const isMutating =
    createSectionMutation.isPending ||
    editManualNameMutation.isPending ||
    exportToPDF.isPending ||
    exportToDOCX.isPending ||
    deleteManualSectionMutation.isPending ||
    deleteManualMutation.isPending;
  const isExporting = exportToPDF.isPending || exportToDOCX.isPending;

  const [activeIndex, setActiveIndex] = useState(0);
  const sectionModal = useDisclosure({ defaultIsOpen: false });
  const deleteModal = useDisclosure({ defaultIsOpen: false });
  const [deleteSection, setDeleteSection] = useState<{
    name: string;
    sectionID: string;
  } | null>(null);
  const editNameModal = useDisclosure({ defaultIsOpen: false });
  const [editName, setEditName] = useState("");
  const viewUsageModal = useDisclosure({ defaultIsOpen: false });
  const [usageSection, setUsageSection] = useState<{
    name: string;
    sectionID: string;
  } | null>(null);
  const [isSection, setIsSection] = useState(true);
  const isOwnManual =
    manualQuery.data?.responsibleUser.userID === app.permissions.INFO.userID;
  const canAddSection =
    app.permissions.MANUAL.UPDATE.ALL ||
    (app.permissions.MANUAL.UPDATE.SELF && isOwnManual);

  const canDeleteManual =
    app.permissions.MANUAL.DELETE.ALL ||
    (app.permissions.MANUAL.DELETE.SELF && isOwnManual);

  const manualSectionRefs = useRef<(HTMLHeadingElement | null)[]>([]);
  const { displayToast } = useToast();

  const handleScroll = useCallback(() => {
    const headingRects = manualSectionRefs.current.map((headingEl) => {
      if (headingEl) {
        return headingEl.getBoundingClientRect();
      }
      return new DOMRect();
    });
    const activeHeading = findActiveHeading(headingRects);
    setActiveIndex(activeHeading);
  }, []);

  useEffect(() => {
    setActiveIndex(0);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  const handleCloseAddSection = () => {
    sectionModal.onClose();
  };

  const handleConfirmAddSection = async (title: string) => {
    sectionModal.onClose();
    if (manualID && manualQuery.isSuccess) {
      await createSectionMutation.mutateAsync({
        section: {
          manualID,
          title,
        },
      });
      sectionsQuery.refetch();
    }
  };

  const handleOpenAddSection = () => {
    if (isMutating) return;
    sectionModal.onOpen();
  };

  const handleCloseDeleteSection = () => {
    deleteModal.onClose();
    setDeleteSection(null);
  };

  const handleConfirmDeleteSection = async () => {
    if (isSection && deleteSection) {
      let success;
      try {
        await deleteManualSectionMutation.mutateAsync({
          sectionID: deleteSection.sectionID,
        });
        success = true;
      } catch (err) {
        success = false;
      } finally {
        displayToast({
          status: success ? "success" : "error",
          title: success
            ? `${deleteSection.name} deleted successfully`
            : `Failed to delete ${deleteSection.name}`,
        });
      }
      setDeleteSection(null);
      deleteModal.onClose();
      sectionsQuery.refetch();
    } else if (!isSection && deleteSection) {
      let success;
      try {
        await deleteManualMutation.mutateAsync({
          manualID: deleteSection.sectionID,
        });
        success = true;
      } catch (err) {
        success = false;
      } finally {
        displayToast({
          status: success ? "success" : "error",
          title: success
            ? `${deleteSection.name} deleted successfully`
            : `Failed to delete ${deleteSection.name}`,
        });
      }
      navigate("/manual");
      deleteModal.onClose();
      sectionsQuery.refetch();
    }
  };

  const handleOpenDeleteSection = (sectionID: string, sectionName: string) => {
    setDeleteSection({ sectionID, name: sectionName });
    deleteModal.onOpen();
  };

  const handleClickContents = (index: number) => {
    const el = manualSectionRefs.current[index];
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleEdit = (sectionID: string) => {
    navigate(`/manual/section/${sectionID}/edit`);
  };

  const handleClickEditName = () => {
    setEditName(manualQuery.data?.name || "");
    editNameModal.onOpen();
  };

  const handleCancelEditName = () => {
    editNameModal.onClose();
  };

  const handleConfirmEditName = async () => {
    editNameModal.onClose();
    if (manualID) {
      await editManualNameMutation.mutateAsync({
        manual: {
          manualID,
          name: editName,
        },
      });
      manualQuery.refetch();
    }
  };

  const handleOpenUsage = (sectionID: string, sectionName: string) => {
    setUsageSection({ sectionID, name: sectionName });
    viewUsageModal.onOpen();
  };

  const handleCloseUsage = () => {
    viewUsageModal.onClose();
    setUsageSection(null);
  };

  const exportHTML = () => {
    const editorExtensions = getEditorExtensions();

    const htmlSections =
      sectionsQuery?.data?.sections
        ?.map((section) => {
          const jsonStr = section?.content?.jsonStr || "";
          const sectionTitle = section?.title || "Untitled Section";
          const lastModified = section?.modifiedTs
            ? new Date(section.modifiedTs)
            : null;
          const modifiedBy = section?.modifiedUser || {};

          try {
            const parsedContent = jsonStr ? JSON.parse(jsonStr) : null;
            const htmlContent = parsedContent
              ? generateHTML(parsedContent, editorExtensions)
              : "<i>No content available for this section</i>";

            return `
          <div class="section">
            <h2 class="section-title">${sectionTitle}</h2>
            <p class="section-meta"><strong>Last Modified:</strong> ${
              lastModified ? lastModified.toLocaleDateString() : "N/A"
            }</p>
            <p class="section-meta"><strong>Modified By:</strong> ${
              modifiedBy.firstName || ""
            } ${modifiedBy.lastName || ""}</p>
            <div class="section-content">${htmlContent}</div>
          </div>
        `;
          } catch (error) {
            console.error("Error parsing JSON:", error);
            return "<div class='section-error'><i>Error loading section content</i></div>";
          }
        })
        .join("") || "";
    const { newLogoPath, logoPath } = app.accountInfo;
    return `
    <!DOCTYPE html>
    <html lang="en">
      <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>${manualQuery.data?.name || "Document"}</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }
          .header {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
          }
          .header img {
            max-height: 72px;
            max-width: 100%;
            margin-right: 20px; /* Adjust spacing between logo and title */
          }
          h1 {
            font-size: 32px;
            margin: 0;
          }
          .section {
            page-break-after: always;
            margin-bottom: 20px;
          }
          .section-header {
            display: flex;
            align-items: center;
            margin-bottom: 10px;
          }
          .section-title {
            font-size: 24px;
            margin: 0;
          }
          .section-meta {
            font-size: 14px;
            color: #666;
            margin: 0 0 5px 0;
          }
          .section-content {
            margin-top: 10px;
          }
          .section-error {
            color: red;
            font-style: italic;
          }
        </style>
      </head>
      <body>
        <div class="header">
          <img src="${newLogoPath || logoPath}" alt="Logo" />
          <h1>${manualQuery.data?.name || "Untitled Document"}</h1>
        </div>
        ${htmlSections}
      </body>
    </html>
    `;
  };

  const handleExportPDF = async () => {
    const htmlStr = exportHTML();
    const result = await exportToPDF.mutateAsync({
      htmlContent: htmlStr,
      fileName: manualQuery.data?.name || "Manual",
    });
    // console.log("downloadUrl", downloadBuff);
    // const blob = new Blob([downloadBuff], {
    //   type: "application/pdf",
    // });

    // Create a download link and trigger download
    const link = document.createElement("a");
    link.href = result.url;
    link.download = `${manualQuery.data?.name || "Manual"}.pdf`;
    link.click();
  };

  const handleExportDOCX = async () => {
    const htmlStr = exportHTML();
    console.log("htmlStr", htmlStr);
    const result = await exportToDOCX.mutateAsync({
      htmlContent: htmlStr,
      fileName: manualQuery.data?.name || "Manual",
    });
    // console.log("downloadUrl", downloadBuff);
    // const blob = new Blob([downloadBuff], {
    //   type: "application/pdf",
    // });

    // Create a download link and trigger download
    const link = document.createElement("a");
    link.href = result.url;
    link.download = `${manualQuery.data?.name || "Manual"}.docx`;
    link.click();
  };

  const sortedSections =
    sectionsQuery.data?.sections.sort((a, b) => {
      return a.order - b.order;
    }) || [];

  const contents =
    sortedSections.map((section) => ({
      label: section.title,
      id: section.manualSectionID,
      link: `#${getSectionHTMLID(section)}`,
    })) || [];

  const sections = manualQuery.isSuccess
    ? sortedSections.map((section, idx) => {
        return (
          <Flex
            w="100%"
            justify="center"
            _last={{ minH: "calc(30vh - var(--chakra-space-top-bar))" }}>
            <ManualSection
              key={section.manualSectionID}
              onClickDelete={() => {
                setIsSection(true);
                handleOpenDeleteSection(section.manualSectionID, section.title);
              }}
              sectionHTMLID={getSectionHTMLID(section)}
              title={section.title}
              contentJSON={section.content.jsonStr || ""}
              modified={{
                firstName: section.modifiedUser.firstName,
                lastName: section.modifiedUser.lastName,
                userID: section.modifiedUser.userID,
                date: section.modifiedTs,
              }}
              responsibleUser={section.responsibleUser}
              manualResponsibleUser={manualQuery.data.responsibleUser}
              onClickEdit={() => handleEdit(section.manualSectionID)}
              onClickUsage={() =>
                handleOpenUsage(section.manualSectionID, section.title)
              }
              ref={(ref) => {
                manualSectionRefs.current[idx] = ref;
              }}
            />
          </Flex>
        );
      })
    : [];

  return (
    <chakra-scope>
      <Box minH="100vh" w="100%">
        <Box
          pos="fixed"
          top="top-bar"
          w="100%"
          h="48px"
          zIndex={100}
          pt="2"
          pl="4"
          pr="4"
          bg="white">
          <VStack w="full" align="start" spacing="0">
            <HStack justify="space-between" w="full">
              <HStack
                align="center"
                spacing={2}
                _hover={{
                  "& .edit-button": { visibility: "visible", opacity: 1 },
                }}>
                <HStack spacing={0}>
                  <Breadcrumbs
                    breadcrumbs={[{ link: "/manual", label: "Manuals" }]}
                  />
                  <Heading
                    as="h1"
                    size="md"
                    color={
                      manualQuery.isFetching || editManualNameMutation.isLoading
                        ? "gray.500"
                        : "gray.700"
                    }>
                    {manualQuery.data?.name || "Manual"}
                  </Heading>
                </HStack>
                <Tooltip label="Edit manual name">
                  <IconButton
                    visibility="hidden"
                    opacity={0}
                    isDisabled={isMutating || isFetching}
                    className="edit-button"
                    size="sm"
                    onClick={handleClickEditName}
                    aria-label="Edit manual name"
                    variant="ghost">
                    <RiPencilLine size="16" />
                  </IconButton>
                </Tooltip>
              </HStack>
              {!isLoading && (
                <ButtonGroup size="sm">
                  <Tooltip
                    label={
                      !canAddSection
                        ? "You don't have permission to edit this manual"
                        : undefined
                    }>
                    <Button
                      isDisabled={isMutating || isFetching || !canAddSection}
                      onClick={handleOpenAddSection}>
                      Add Section
                    </Button>
                  </Tooltip>
                  <Menu>
                    <MenuButton
                      as={IconButton}
                      isDisabled={isExporting || isMutating || isFetching}
                      isLoading={isExporting}
                      icon={<ExportIcon />}
                    />
                    <MenuList>
                      <MenuItem onClick={handleExportPDF}>
                        Export as PDF
                      </MenuItem>
                      <MenuItem onClick={handleExportDOCX}>
                        Export as Word Document
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  <Menu>
                    <MenuButton
                      colorScheme="red"
                      as={IconButton}
                      icon={<DeleteIcon />}
                    />
                    <MenuList>
                      <MenuItem
                        icon={<DeleteIcon />}
                        isDisabled={
                          isMutating || isFetching || !canDeleteManual
                        }
                        color="red.800"
                        onClick={() => {
                          setIsSection(false);
                          handleOpenDeleteSection(
                            manualID || "",
                            manualQuery.data?.name || "",
                          );
                        }}>
                        Delete Manual
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </ButtonGroup>
              )}
            </HStack>
          </VStack>
        </Box>
        {isLoading ? (
          <Stack
            mt="24"
            w="calc(100vw-332px)"
            flex="2"
            align="center"
            justify="center">
            <FullLoader />
          </Stack>
        ) : (
          <Stack
            mt="52px"
            w="calc(100vw - 332px)"
            flex="2"
            align="start"
            justify="center"
            spacing="16">
            {sections}
          </Stack>
        )}
        <Flex align="center" justify="center" w="calc(100vw - 332px)">
          {!isFetching && (
            <Flex
              justify="center"
              pt="16"
              minH="70vh"
              maxW="container.lg"
              w="100%">
              {canAddSection && (
                <Stack
                  role="button"
                  onClick={handleOpenAddSection}
                  borderRadius="sm"
                  cursor="pointer"
                  align="center"
                  justify="center"
                  maxH="320px"
                  w="100%"
                  borderStyle="dashed"
                  borderWidth="2px"
                  _hover={{
                    borderColor: "brand.200",
                  }}>
                  <HStack>
                    <RiAddLine size="24" />
                    <Text fontSize="xl">Add new section</Text>
                  </HStack>
                </Stack>
              )}
            </Flex>
          )}
        </Flex>
        <Box
          pos="fixed"
          top="calc(var(--chakra-space-top-bar) + 48px + 1rem)"
          right="1"
          w="340px">
          {manualID && manualQuery.isSuccess && (
            <ManualDetailsCard manualID={manualID} />
          )}
          <TableOfContents
            items={contents}
            activeIndex={activeIndex}
            onClick={handleClickContents}
            manualDetails={{
              manualID: manualID || "",
              responsibleUser: manualQuery.data?.responsibleUser.userID || "",
            }}
          />
        </Box>
      </Box>
      <AddSectionModal
        isOpen={sectionModal.isOpen}
        onClose={handleCloseAddSection}
        onConfirm={handleConfirmAddSection}
      />
      <EditManualNameModal
        title={editName}
        isOpen={editNameModal.isOpen}
        onClose={handleCancelEditName}
        onConfirm={handleConfirmEditName}
        onChangeTitle={(value) => setEditName(value)}
      />
      <ConfirmDeleteModal
        title={deleteSection?.name || ""}
        isManualSection={isSection}
        isOpen={deleteModal.isOpen}
        onClose={handleCloseDeleteSection}
        onDelete={handleConfirmDeleteSection}
      />
      <ViewUsageModal
        sectionName={usageSection?.name || ""}
        isOpen={viewUsageModal.isOpen}
        onClose={handleCloseUsage}
        sectionID={usageSection?.sectionID}
      />
    </chakra-scope>
  );
}
