import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  VStack,
} from "@chakra-ui/react";
import { useState } from "react";
import { AdminPortalAccount } from "../../../shared/v2/definitions/admin.types";
import { api } from "../../../utilities/apiRequest";
import useToast from "../../../utilities/useToast";

type EditAccountNameModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onRefresh: () => void;
  account: AdminPortalAccount;
};

export function EditAccountNameModal({
  isOpen,
  onClose,
  account,
  onRefresh,
}: EditAccountNameModalProps) {
  const updateNameMutation = api.admin.account.updateName.useMutation();

  const { displayToast } = useToast();

  const [accountName, setAccountName] = useState("");

  const handleClose = () => {
    setAccountName("");
    onClose();
  };

  const handleClickChangeName = async () => {
    try {
      const res = await updateNameMutation.mutateAsync({
        accountID: account.accountID,
        accountName,
      });
      onRefresh();
      handleClose();
    } catch (e) {
      console.error(e);
      displayToast({
        status: "error",
        title: "Failed to change account name",
      });
    }
  };

  return (
    <Modal
      motionPreset="slideInBottom"
      size="2xl"
      isOpen={isOpen}
      onClose={handleClose}>
      <ModalOverlay />

      <ModalContent>
        <chakra-scope>
          <ModalHeader>Edit account name</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              handleClose();
            }}
          />
          <ModalBody>
            <VStack align="start">
              <Text>
                You are about to change the account name of{" "}
                <Box as="span" fontWeight="bold" fontStyle="italic">
                  {account.accountName}
                </Box>
              </Text>

              <FormControl>
                <FormLabel htmlFor="acc-name" mb="0">
                  New account name
                </FormLabel>
                <Input
                  id="acc-name"
                  onChange={(e) => setAccountName(e.target.value)}
                  value={accountName}
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="gray"
              mr={3}
              isDisabled={updateNameMutation.isPending}
              onClick={handleClose}>
              Cancel
            </Button>
            <Tooltip label={accountName.length >= 1 ? null : "👉👈"}>
              <Button
                isDisabled={accountName.length < 1}
                loadingText="Saving"
                isLoading={updateNameMutation.isPending}
                onClick={handleClickChangeName}>
                Edit name
              </Button>
            </Tooltip>
          </ModalFooter>
        </chakra-scope>
      </ModalContent>
    </Modal>
  );
}
